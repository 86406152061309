import React, { useCallback, useState } from 'react';
import styles from './styles.module.css';
import { Badge, Button, Form } from 'react-bootstrap';
import ManageVariableDialog from '../../components/manage_variable_dialog';
import { useDispatch, useSelector } from 'react-redux';
import { InterfaceStudioState } from '../../store';
import Confirmation from 'web_ui/confirmation';
import { deleteVariable } from '../../store/actions/variables';
import Icon from 'web_ui/icon';
import { FrontendVariable } from 'modules/designer/types';
import { useTranslation } from 'react-i18next';
import { t } from 'i18next';
import HelpPopover from 'web_ui/workboard/sidebar/controls/components/Popover';

const VariableInstanceItem = ({
  variableId,
  variableName,
  variableInfo
}: {
  variableId: string;
  variableName: string;
  variableInfo: FrontendVariable;
}) => {
  const view_id = useSelector((state: InterfaceStudioState) => state.studio.view_id);
  const dispatch = useDispatch();
  const [showVariableDialog, setShowVariableDialog] = useState(false);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

  const deleteVar = () => {
    if (!view_id) return;
    dispatch(deleteVariable(view_id, variableId));
    setShowConfirmationDialog(false);
  };

  const handleEditVariable = () => {
    setShowVariableDialog(true);
  };

  const handleDeleteVariable = (event: React.MouseEvent<HTMLSpanElement>) => {
    event.stopPropagation();
    setShowConfirmationDialog(true);
  };

  return (
    <>
      <div
        className={`border ${styles.VariableWrapper}`}
        style={{ cursor: 'pointer' }}
        onClick={handleEditVariable}
      >
        <div className={`${styles.VariableDescription}`}>
          <div className={styles.VariableLeftSideWrapper}>
            <Badge className={`${styles.VariableBadge} ${styles.VariableBadgeBackground}`}>
              {variableName}
            </Badge>
          </div>
        </div>
        <div className={styles.wrapperIcons}>
          <HelpPopover
            placement={'top'}
            helpBoxProps={{
              title: t('editButton') ?? 'editButton'
            }}
          >
            <div
              id={`editButton-${variableName}`}
              className={`bg-body-tertiary shadow-sm ${styles.itemIcons}`}
              onClick={handleEditVariable}
            >
              <Icon iconName="pen-to-square" extraProps={`text-secondary h7`} />
            </div>
          </HelpPopover>
          <HelpPopover
            placement={'top'}
            helpBoxProps={{
              title: t('Delete') ?? 'Delete'
            }}
          >
            <div
              id={`deleteButton-${variableName}`}
              className={`bg-body-tertiary shadow-sm ${styles.itemIcons}`}
              onClick={handleDeleteVariable}
            >
              <Icon iconName="trash" extraProps={`text-secondary h7`} />
            </div>
          </HelpPopover>
        </div>
      </div>
      {view_id && (
        <ManageVariableDialog
          view_id={view_id}
          show={showVariableDialog}
          onClose={() => setShowVariableDialog(false)}
          variable={variableInfo}
          editMode={true}
        />
      )}
      {showConfirmationDialog && (
        <Confirmation
          show={showConfirmationDialog}
          message={`${t('organizations.new_organization.DeleteVariable')} (${variableName})`}
          onCancel={() => setShowConfirmationDialog(false)}
          onConfirmation={deleteVar}
          onClose={() => setShowConfirmationDialog(false)}
        />
      )}
    </>
  );
};

export function VariableInstance() {
  const view_id = useSelector((state: InterfaceStudioState) => state.studio.view_id);
  const { t } = useTranslation();
  const variables = useSelector((state: InterfaceStudioState) => state.variables);
  const objects = useSelector((state: InterfaceStudioState) => state.objects);
  const [showCreateVariableDialog, setShowCreateVariableDialog] = useState(false);
  const [search, setSearch] = useState('');

  const applyFilterVariables = (f: FrontendVariable): boolean => {
    if (f.name.toLowerCase().includes(search.toLowerCase())) return true;
    if (f.type.toLowerCase().includes(search.toLowerCase())) return true;
    return false;
  };

  const getObjectName = useCallback(
    (objectId: string) => {
      if (!objects[objectId]) return '';
      return objects[objectId].name ?? '';
    },
    [objects]
  );

  const getVariableSignature = (variable: FrontendVariable): string => {
    let listSymbol = '';
    if (variable.list) listSymbol = '[ ]';
    let typeSymbol = '';
    if (variable.type === 'OBJECT') typeSymbol = getObjectName(variable.objectUuid || '');
    else if (variable.type) typeSymbol = variable.type;
    return typeSymbol + listSymbol + ' ' + String(variable.name);
  };

  const variableListItems = () => {
    return Object.values(variables)
      .filter((f) => applyFilterVariables(f))
      .map((f) => {
        return (
          <VariableInstanceItem
            key={f.uuid}
            variableId={f.uuid}
            variableName={getVariableSignature(f)}
            variableInfo={f}
          />
        );
      });
  };

  return (
    <>
      <div className={styles.FilterInput} translate="no">
        <Form className={styles.FilterInputForm}>
          <Form.Control
            id={'designer-behavior-searchinput-variable'}
            type="search"
            placeholder="Search"
            aria-label="Search"
            onChange={(e) => setSearch(e.target.value)}
            value={search}
          />
        </Form>
        <div>
          <Button
            className="me-3"
            id={'createButton'}
            variant="primary"
            onClick={() => {
              setShowCreateVariableDialog(true);
            }}
          >
            <Icon iconName="plus" extraProps="pe-1"></Icon>
            {t('organizations.new_organization.Create')}
          </Button>
        </div>
      </div>

      <div className={styles.Content}>{variableListItems()}</div>

      {view_id && (
        <ManageVariableDialog
          view_id={view_id}
          show={showCreateVariableDialog}
          onClose={() => setShowCreateVariableDialog(false)}
          editMode={false}
        />
      )}
    </>
  );
}
