import React, { useState } from 'react';
import styles from './styles.module.css';
import { ObjectSimple } from 'modules/logic_builder/types';
import { ObjectDialog } from '../dialogs/object_dialog';
import { Column, EnumFrame, Relationship, Table } from 'modules/modeler/types';
import { ModuleInfo } from 'modules/dashboard/types';
import Icon from '../../../../web_ui/icon';
import HelpPopover from 'web_ui/workboard/sidebar/controls/components/Popover';
import { t } from 'i18next';
import Confirmation from 'web_ui/confirmation';
import { ObjectsService } from 'modules/logic_builder/services';
import { useParams } from 'react-router-dom';
import { PopupAlert } from 'web_ui/popup_alert';

type ObjectInstanceProps = {
  objectInstance: ObjectSimple;
  cursor?: string;
  onEdit: (e: ObjectSimple) => void;
  onPreviewObject: (objectUuid: string) => void;
  tables: Table[];
  columns: Column[];
  relationships: Relationship[];
  enums: EnumFrame[];
  loadingData?: boolean;
  allObjects?: ObjectSimple[];
  modules?: ModuleInfo[];
  objectsWithDeps?: ObjectSimple[];
  getObj?: (UUIDsCreated?: string[]) => void;
  fetchObj?: (moduleId: string) => Promise<ObjectSimple[]>;
};

export function ObjectInstance(props: ObjectInstanceProps) {
  const [showObjectEditor, setShowObjectEditor] = useState<boolean>(false);
  const [objectEditUuid, setObjectEditUuid] = useState<string>();
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const { module_id } = useParams();
  const [showConfirmationOfConflicts, setShowConfirmationOfConflicts] = useState<boolean>(false);

  async function onDelete() {
    if (!module_id) return;
    try {
      await ObjectsService.deleteObject(props.objectInstance.uuid).then((res) => {
        if (res.status === 409) {
          setShowConfirmationOfConflicts(true);
        } else {
          if (props.getObj) {
            props.getObj();
            setShowConfirmationDialog(false);
          }
          if (props.fetchObj) {
            props.fetchObj(module_id);
            setShowConfirmationDialog(false);
          }
        }
      });
    } catch (error) {
      setAlertMessage('Error deleting Object: Conflict with relations');
    }
  }

  async function onDeleteForce() {
    if (!module_id) return;
    await ObjectsService.deleteObject(props.objectInstance.uuid, true).then((res) => {
      if (res.ok) {
        if (props.fetchObj) {
          props.fetchObj(module_id);
        }
        setShowConfirmationDialog(false);
        setShowConfirmationOfConflicts(false);
      }
    });
  }

  return (
    <>
      <div
        id={props.objectInstance.name}
        title={props.objectInstance.native ? 'Native' : ''}
        className={styles.containerObj}
        onClick={(event) => {
          event.stopPropagation();
          setShowObjectEditor(true);
          setObjectEditUuid(props.objectInstance.uuid);
        }}
        translate="no"
      >
        <div className={styles.headObj}>
          {props.objectInstance.name}
          <div className={styles.borderRight}></div>
        </div>
        <div id="objectBody" className={styles.bodyObj}>{`{ ${
          props.objectInstance.objectItems &&
          props.objectInstance.objectItems.length > 0 &&
          props.objectInstance.objectItems.map((item, index) => {
            return `${item.name}: ${item.dataType}`;
          })
        } }`}</div>
        {props.objectInstance.entityName != null ? (
          <div className={styles.footerObj}>
            <div className={styles.footerTag}>{props.objectInstance.entityName}</div>
          </div>
        ) : (
          <div className={styles.footerObj} />
        )}
        <div className={styles.wrapperIcons}>
          <HelpPopover
            placement={'top'}
            helpBoxProps={{
              title: t('CodePreview') ?? 'CodePreview'
            }}
          >
            <div
              id={`codePreview-${props.objectInstance.name}`}
              className={`bg-body-tertiary shadow-sm ${styles.itemIcons} text-secondary`}
              onClick={(event) => {
                event.stopPropagation();
                props.onPreviewObject(props.objectInstance.uuid);
              }}
            >
              <Icon iconName="fa-solid fa-code" extraProps={` h7`} />
            </div>
          </HelpPopover>
          <HelpPopover
            placement={'top'}
            helpBoxProps={{
              title: t('editButton') ?? 'editButton'
            }}
          >
            <div
              id={`editButton-${props.objectInstance.name}`}
              className={`bg-body-tertiary shadow-sm ${styles.itemIcons} text-secondary`}
              onClick={() => {
                setShowObjectEditor(true);
                setObjectEditUuid(props.objectInstance.uuid);
              }}
            >
              <Icon iconName="pen-to-square" extraProps={` h7`} />
            </div>
          </HelpPopover>
          <HelpPopover
            placement={'top'}
            helpBoxProps={{
              title: t('Delete') ?? 'Delete'
            }}
          >
            <div
              id={`deleteButton-${props.objectInstance.name}`}
              className={`bg-body-tertiary shadow-sm ${styles.itemIcons} text-secondary`}
              onClick={(event) => {
                event.stopPropagation();
                setShowConfirmationDialog(true);
              }}
            >
              <Icon iconName="trash" extraProps={` h7`} />
            </div>
          </HelpPopover>
        </div>
      </div>
      <ObjectDialog
        show={showObjectEditor}
        onClose={() => {
          setShowObjectEditor(false);
          setObjectEditUuid(undefined);
        }}
        editMode={!!objectEditUuid}
        dialogTitle={objectEditUuid ? 'EditObject' : 'CreateObject'}
        objectId={objectEditUuid}
        tables={props.tables}
        columns={props.columns}
        enums={props.enums}
        relationships={props.relationships}
        entityMode={false}
        preselectedEntity={props.objectInstance.entityUuid}
        modules={props.modules ?? []}
        loadingData={props.loadingData}
        objectsWithDeps={props.objectsWithDeps}
      />
      <Confirmation
        show={showConfirmationOfConflicts}
        message={`${t('deleteQuotes.conflict')}${' '}(${props.objectInstance.name})`}
        onConfirmation={onDeleteForce}
        onCancel={() => setShowConfirmationOfConflicts(false)}
        onClose={() => setShowConfirmationOfConflicts(false)}
      />
      <Confirmation
        show={showConfirmationDialog}
        message={`${t('deleteQuotes.object')}`}
        onConfirmation={onDelete}
        onCancel={() => setShowConfirmationDialog(false)}
        onClose={() => setShowConfirmationDialog(false)}
      />
      {alertMessage !== '' && (
        <PopupAlert i18nKey={alertMessage} onClose={() => setAlertMessage('')} variant={'danger'} />
      )}
    </>
  );
}
