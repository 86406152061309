import React, { DragEvent } from 'react';
import { ListGroup } from 'react-bootstrap';

export type ComponentCardProps = {
  type: string;
  name: string;
  description: string;
  icon?: string;
};

function ComponentCard(props: ComponentCardProps) {
  function handleDragStart(ev: DragEvent<HTMLDivElement>) {
    ev.dataTransfer.setData('exocode/frame-type', props.type);
    ev.dataTransfer.dropEffect = 'copy';
  }

  return (
    <>
      <ListGroup.Item
        action
        variant="secondary"
        className={`${
          props.type === 'TABLE' ? 'border-primary' : 'border-danger'
        } bg-body-secondary rounded border-primary border-0 border-start border-4 me-1 mb-2`}
        draggable={true}
        onDragStart={handleDragStart}
        id={`draggable-component-card-${props.type.toLocaleLowerCase()}`}
        translate="no"
      >
        <div className="card-body" style={{ padding: '0.4rem 0.5rem' }}>
          <h6 className="card-subtitle fw-normal" style={{ marginTop: '0', fontSize: '15px' }}>
            <i className={'fa fa-' + props.icon} style={{ fontSize: '1.1rem' }} />
            <div style={{ marginLeft: '25px', marginTop: '-20px' }}>{props.name}</div>
          </h6>
        </div>
      </ListGroup.Item>
    </>
  );
}

export default ComponentCard;
