import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';

import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.css';
import './styles.css';
import 'bootstrap/dist/js/bootstrap';
import './assets/i18n/i18n';
import './assets/fonts/fonts.css';

import Exocode from './exocode';
import 'bootstrap/dist/css/bootstrap.min.css';

// https://github.com/facebook/react/issues/11538#issuecomment-417504600
// https://issues.chromium.org/issues/41407169
// Exocode issue being tracked: https://github.com/ARTSOFT-TI/exocode/issues/4532
if (typeof Node === 'function' && Node.prototype) {
  const originalRemoveChild = Node.prototype.removeChild;
  Node.prototype.removeChild = function <T extends Node>(child: T): T {
    if (child.parentNode !== this) {
      if (console) {
        console.error('Cannot remove a child from a different parent', child, this);
      }
      return child;
    }
    return originalRemoveChild.call(this, child) as T;
  };

  const originalInsertBefore = Node.prototype.insertBefore;
  Node.prototype.insertBefore = function <T extends Node>(
    newNode: T,
    referenceNode: Node | null
  ): T {
    if (referenceNode && referenceNode.parentNode !== this) {
      if (console) {
        console.error(
          'Cannot insert before a reference node from a different parent',
          referenceNode,
          this
        );
      }
      return newNode;
    }
    return originalInsertBefore.call(this, newNode, referenceNode) as T;
  };
}

ReactDOM.render(
  // <React.StrictMode>
  <Suspense fallback="loading">
    <Exocode />
  </Suspense>,
  // </React.StrictMode>,
  document.getElementById('root')
);
