import React, { useCallback, useEffect, useRef, useState } from 'react';
import styles from './styles.module.css';
import { Dropdown, FormControl, InputGroup } from 'react-bootstrap';
import Icon from 'web_ui/icon';
import ContextMenu from 'web_ui/workboard/ContextMenu';
import { useTranslation } from 'react-i18next';
import ContextMenuItem from 'web_ui/workboard/ContextMenuItem';
import Confirmation from 'web_ui/confirmation';
import { ContextMenuItem as ContextMenuItemType } from '..';
import CodeEditorModal from 'web_ui/code_editor_modal';
import { CodePreviewType } from 'web_ui/code_editor_modal/editor';
import { openMenuEvent } from './folder';

const ICON_COLORS = {
  'table-columns': '#ffe0b3',
  'window-restore': '#B3D1FF',
  'window-maximize': '#D1B3FF ',
  table: '#9595ff',
  columns: '#6d141c'
};

interface FolderItemProps {
  itemId: string;
  itemName: string;
  isSelected: boolean;
  handleSelectItem: (itemId: string) => void;
  className?: string;
  handleSaveName: (name: string) => void;
  deleteItem: () => Promise<void>;
  contextMenus: ContextMenuItemType[];
  validate: (name: string) => string;
  iconName?: string;
  disabled?: boolean;
  type?: string;
}

export function FolderItem({
  itemId,
  itemName,
  isSelected,
  handleSelectItem,
  className,
  handleSaveName,
  deleteItem,
  contextMenus,
  validate,
  iconName,
  disabled,
  type
}: FolderItemProps) {
  const { t } = useTranslation();
  const [newName, setNewName] = useState('');
  const [currentName, setCurrentName] = useState('');
  const [isEditingName, setIsEditingName] = useState(false);
  const [showDeleteDialogConfirmation, setShowDeleteDialogConfirmation] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [errorMessage, setErrorMessage] = useState('');

  const [showCodePreviewDialog, setShowCodePreviewDialog] = useState<boolean>(false);
  const [showDropdown, setShowDropdown] = useState(false);

  useEffect(() => {
    setCurrentName(itemName);
  }, [itemName]);

  const handleEditName = (): void => {
    setIsEditingName(true);
    setNewName(currentName);
    setErrorMessage('');
  };

  const handleInputBlur = (newName: string): void => {
    setIsEditingName(false);
    setErrorMessage('');
    if (!(newName === currentName || errorMessage)) {
      setCurrentName(newName);
      handleSaveName(newName);
    }
  };

  const renameContextMenu: ContextMenuItemType = {
    label: 'designer.views.Rename',
    onClick: () => handleEditName(),
    icon: 'pencil'
  };

  const handleDelete = useCallback(async () => {
    await deleteItem();
    setShowDeleteDialogConfirmation(false);
  }, [deleteItem]);

  const handleChangeName = useCallback(
    (name: string) => {
      setNewName(name);
      setErrorMessage(validate(name));
    },
    [validate]
  );

  useEffect(() => {
    const handleMenuOpen = () => {
      setShowDropdown(false);
    };

    window.addEventListener('contextMenuOpen', handleMenuOpen);

    return () => {
      window.removeEventListener('contextMenuOpen', handleMenuOpen);
    };
  }, []);

  useEffect(() => {
    const closeAll = () => {
      setShowDropdown(false);
      const event = new CustomEvent('contextmenu_close');
      document.dispatchEvent(event);
    };

    document.addEventListener('iframe_contextmenu', closeAll);

    return () => {
      document.removeEventListener('iframe_contextmenu', closeAll);
    };
  }, []);

  const handleEllipsisClick = () => {
    window.dispatchEvent(openMenuEvent);
    setShowDropdown(true);
  };

  const handleContextMenu = (e: React.MouseEvent) => {
    window.dispatchEvent(openMenuEvent);
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <>
      <div
        className={`${styles.viewItemContainer} ps-2 ${className}`}
        onClick={() => !disabled && handleSelectItem(itemId)}
        ref={wrapperRef}
        onContextMenu={handleContextMenu}
        translate="no"
      >
        <div
          id={`${currentName}Sidebar`}
          className={`${
            styles.viewItem
          } w-100 d-flex align-items-center justify-content-between p-2 ${
            disabled && styles.disabledFolderItem
          }`}
        >
          <div className={`d-flex align-items-center ${styles.viewTitleData}`}>
            <div className="border-end border-2 pe-2 text-body-tertiary" style={{ cursor: 'grab' }}>
              <Icon iconName="ellipsis-vertical" />
            </div>
            {iconName && (
              <div
                className="ms-2"
                style={{ color: ICON_COLORS[iconName as keyof typeof ICON_COLORS] }}
              >
                <Icon iconName={iconName} />
              </div>
            )}
            {isEditingName ? (
              <InputGroup>
                <FormControl
                  id="formName"
                  autoFocus
                  size="sm"
                  type="text"
                  className={`ms-2 w-50 ${errorMessage && styles.invalidTextInput}`}
                  value={newName}
                  onChange={(e) => handleChangeName(e.target.value)}
                  onBlur={(e) => handleInputBlur(e.target.value)}
                />
              </InputGroup>
            ) : (
              <div
                onDoubleClick={handleEditName}
                className={`${isSelected && 'text-body-emphasis'} ms-2 text-truncate`}
                style={{ maxWidth: 120 }}
              >
                {currentName}
              </div>
            )}
          </div>
          <div className={`d-flex`}>
            <div
              className="border-end border-2 pe-2 text-body-tertiary"
              style={{ cursor: 'pointer' }}
              onClick={handleEllipsisClick}
            >
              <Icon iconName="ellipsis-h" />
            </div>
            <Dropdown show={showDropdown} onToggle={(isOpen) => setShowDropdown(isOpen)}>
              <Dropdown.Toggle
                className={styles.dropdownButton}
                as={'span'}
                variant="success"
                id={`dropdownItem${currentName}`}
              />
              <div style={{ position: 'absolute' }}>
                <Dropdown.Menu>
                  {[renameContextMenu, ...contextMenus].map((menu, index) => {
                    return (
                      <Dropdown.Item
                        key={index}
                        onClick={() => menu.onClick()}
                        id={`${t(menu.label).toLocaleLowerCase()}Button`}
                      >
                        <Icon iconName={`${menu.icon ?? ''} me-2`} />
                        {`${t(menu.label)}`}
                      </Dropdown.Item>
                    );
                  })}
                  <Dropdown.Item
                    onClick={() => setShowDeleteDialogConfirmation(true)}
                    id={'deleteButton'}
                  >
                    <Icon iconName={`trash me-2`} />
                    {`${t('Delete')}`}
                  </Dropdown.Item>
                  {type === 'TABLE' && (
                    <>
                      <Dropdown.Divider />
                      <ContextMenuItem
                        icon="fa-solid fa-code"
                        label={t('CodePreview')}
                        onClick={() => setShowCodePreviewDialog(true)}
                      />
                    </>
                  )}
                </Dropdown.Menu>
              </div>
            </Dropdown>
          </div>
        </div>
      </div>
      {!disabled && (
        <ContextMenu elementRef={wrapperRef} offsetY={-200}>
          {[renameContextMenu, ...contextMenus].map((menu, index) => {
            return (
              <ContextMenuItem
                key={index}
                icon={menu.icon ?? ''}
                label={t(menu.label)}
                onClick={() => menu.onClick()}
              />
            );
          })}
          <ContextMenuItem
            icon={'trash'}
            label={t('Delete')}
            onClick={() => setShowDeleteDialogConfirmation(true)}
          />
          {type === 'TABLE' && (
            <>
              <Dropdown.Divider />
              <ContextMenuItem
                icon="fa-solid fa-code"
                label={t('CodePreview')}
                onClick={() => setShowCodePreviewDialog(true)}
              />
            </>
          )}
        </ContextMenu>
      )}
      <Confirmation
        show={showDeleteDialogConfirmation}
        message={
          type === 'OBJECT'
            ? `${t('logicBuilder.objectsTab.DeleteObjectConfirmation')} (${itemName})`
            : type === 'CLASS'
              ? `${t('logicBuilder.service.deleteService')} (${itemName})`
              : type === 'SCHEDULERS'
                ? `${t('logicBuilder.deleteScheduled')} (${itemName})`
                : type === 'CONTROLLER'
                  ? `${t('logicBuilder.deleteController')} (${itemName})`
                  : type === 'PAGE'
                    ? `${t('designer.confirmationPageDelete')} (${itemName})`
                    : type === 'MODAL'
                      ? `${t('designer.confirmationModalDelete')} (${itemName})`
                      : type === 'LAYOUT'
                        ? `${t('designer.confirmationLayoutDelete')} (${itemName})`
                        : type === 'TABLE'
                          ? `${t('designer.confirmationTableDelete')} (${itemName})`
                          : type === 'ENUM'
                            ? `${t('modeler.Delete Enum')} (${itemName})`
                            : `${t('logicBuilder.')} (${itemName})`
        }
        onConfirmation={async () => await handleDelete()}
        onCancel={() => {
          setShowDeleteDialogConfirmation(false);
        }}
        onClose={() => {
          setShowDeleteDialogConfirmation(false);
        }}
      />
      {itemId && (
        <CodeEditorModal
          show={showCodePreviewDialog}
          handleClose={() => setShowCodePreviewDialog(false)}
          id={itemId}
          previewType={CodePreviewType.TABLE}
        />
      )}
    </>
  );
}
