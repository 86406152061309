import React from 'react';

const useTitle = (title: string, ...deps: unknown[]) => {
  const documentDefined = typeof document !== 'undefined';
  const originalTitle = React.useRef(documentDefined ? document.title : '');

  React.useEffect(() => {
    if (!documentDefined) {
      return;
    }

    if (document.title !== title) {
      document.title = title;
    }

    const titleNode = originalTitle.current;

    return () => {
      document.title = titleNode;
    };
  }, [documentDefined, title]);
};

export default useTitle;
