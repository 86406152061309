import { TFunction } from 'i18next';
import { SessionContextType } from '../modules/auth/store';

/*  Performs date2 - date1 and returns the result in days */
export const differenceInDays = (date1: Date, date2: Date) => {
  const _MS_PER_DAY = 1000 * 60 * 60 * 24;
  const utcDate1 = Date.UTC(date1.getFullYear(), date1.getMonth(), date1.getDate());
  const utcDate2 = Date.UTC(date2.getFullYear(), date2.getMonth(), date2.getDate());
  return Math.floor((utcDate2 - utcDate1) / _MS_PER_DAY);
};

export const textLastModification = (
  modificationTime: string,
  t: TFunction<'translation', undefined>,
  session: SessionContextType
): string => {
  const now = new Date();
  const lastModification = new Date(modificationTime);
  //Seconds
  let difference = (now.getTime() - lastModification.getTime()) / 1000;

  if (difference < 60) {
    return `${t('organizations.organization.modification.updated')} ${~~difference} ${
      ~~difference === 1
        ? `${t('organizations.organization.modification.second')}`
        : `${t('organizations.organization.modification.seconds')}`
    } ${t('organizations.organization.modification.ago')}`;
  }
  // Minutes
  difference = difference / 60;
  if (difference < 60) {
    return `${t('organizations.organization.modification.updated')} ${~~difference} ${
      ~~difference === 1
        ? `${t('organizations.organization.modification.minute')}`
        : `${t('organizations.organization.modification.minutes')}`
    } ${t('organizations.organization.modification.ago')}`;
  }
  // Hours
  difference = difference / 60;
  if (difference < 24) {
    return `${t('organizations.organization.modification.updated')} ${~~difference} ${
      ~~difference === 1
        ? `${t('organizations.organization.modification.hour')}`
        : `${t('organizations.organization.modification.hours')}`
    } ${t('organizations.organization.modification.ago')}`;
  }
  // Days
  difference = difference / 24;
  if (difference < 7) {
    return `${t('organizations.organization.modification.updated')} ${~~difference} ${
      ~~difference === 1
        ? `${t('organizations.organization.modification.day')}`
        : `${t('organizations.organization.modification.days')}`
    } ${t('organizations.organization.modification.ago')}`;
  }
  // More than 7 days
  // TODO change the locales by the location of user
  let lang: string = session.language;
  if (lang === 'en') lang = lang + '-EN';
  else {
    lang = lang + '-PT';
  }
  return `updated on ${lastModification.toLocaleString(lang, { dateStyle: 'medium' })}`;
};
