import React, { ChangeEvent, useState } from 'react';
import { AppFullInfo, DataSourcesType } from 'modules/project/types';
import { additionalmodules, BackendFrameworkList, DatabaseList } from './data';
import { Form, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import { BackendFramework, DataSource } from 'modules/project/enum';
import produce from 'immer';
import { useTranslation } from 'react-i18next';
import { Authorization } from 'modules/auth/session/authorization';
import { SystemRoleAuthorityName } from 'modules/auth/types/auth_types';

export type Step4Props = {
  /** Application info to use on the form */
  appInfo: AppFullInfo;
  /** Callback on form changes */
  onChange: <K extends keyof AppFullInfo>(key: K, value: AppFullInfo[K]) => void;
  /* set state of errors */
  updateErrorMessage: (s: string) => void;
};

export type BackendTechOption<T> = {
  /** Tech stack enum */
  id: T;
  /** Name of the lib/framework to show on the UI */
  name: string;
  /** Icon of the lib/framework to show on the UI */
  icon?: string;
  /** Disables this option and shows a "Soon" badge */
  unavailable?: boolean;
};

/**
 * Step4 of the application creation wizard.
 * In this step the user must select the backend tech stack.
 *
 * New technologies can be added to `BackendFrameworkList` or `DatabaseList`.
 *
 * @component
 */
function Step4(props: Step4Props) {
  const { t } = useTranslation();
  const [validPackage, setValidPackage] = useState(isValidPackageName(props.appInfo.domain));
  /**
   * Handles the application's database selection.
   */
  function handleDatabaseChange(value: DataSource) {
    const newDataSource: DataSourcesType = {
      type: value,
      options: {}
    };

    const updatedDataSources: DataSourcesType[] = [newDataSource];
    props.onChange('data_sources', updatedDataSources);
  }

  /**
   * Handles the application's backend framework selection.
   */
  function handleFrameworkChange(e: ChangeEvent<HTMLInputElement>) {
    const backend = produce(props.appInfo.backend, (draft) => {
      draft.framework = e.currentTarget.value as BackendFramework;
    });

    props.onChange('backend', backend);
  }

  // function handleAddModulesChange(e: ChangeEvent<HTMLInputElement>) {
  //   const backend = produce(props.appInfo.backend, (draft) => {
  //     draft.framework = e.currentTarget.value as BackendFramework;
  //   });

  //   props.onChange('backend', backend);
  // }

  /**
   * Handles the application's backend extra information change.
   */
  function handleChange<K extends keyof AppFullInfo>(key: K, value: AppFullInfo[K]) {
    props.onChange(key, value);
  }

  function isValidPackageName(domain: string): boolean {
    const domainPattern = /^[a-z._0-9]+\.[a-z_0-9]+$/;
    const isValid = domainPattern.test(domain);
    isValid
      ? props.updateErrorMessage('')
      : props.updateErrorMessage(t('new_application.step2.InvalidDomain'));
    return isValid;
  }

  function handleAppInfoChange<K extends keyof AppFullInfo>(key: K, value: AppFullInfo[K]) {
    if (!value) return;

    const text = value.toString().toLowerCase().trim().replace(/\s/g, '');
    setValidPackage(isValidPackageName(text));
    handleChange('domain', text);
  }

  return (
    <>
      <h4 className="text-body-emphasis">{t('new_application.step3.Backend')}</h4>

      <h5 className="text-body-primary mt-5 mb-3">{t('Framework')}</h5>
      {BackendFrameworkList.map((framework) => (
        <ToggleButton
          key={framework.id}
          className="me-3 mb-2 position-relative"
          id={framework.id}
          type="radio"
          name="framework"
          variant={
            props.appInfo.backend.framework === framework.id ? 'primary' : 'outline-secondary'
          }
          value={framework.id}
          disabled={framework.unavailable}
          checked={props.appInfo.backend.framework === framework.id}
          onChange={handleFrameworkChange}
        >
          <span className={`fa fa-${framework.icon}`} /> {framework.name}
        </ToggleButton>
      ))}

      <Form.Group className="mb-3" controlId="formDomain">
        <Form.Label>{t('Package')}</Form.Label>
        <Form.Control
          type="text"
          placeholder="ex: exocoding.com"
          value={props.appInfo.domain}
          maxLength={255}
          onChange={(e) => {
            handleAppInfoChange('domain', e.target.value);
          }}
          onKeyDown={(ev) => {
            if (ev.key === 'Enter') {
              ev.stopPropagation();
              ev.preventDefault();
            }
          }}
          required
          pattern="[a-z.\-0-9]+\.[a-z]+$"
          isInvalid={!validPackage}
        />
        <Form.Control.Feedback type={'invalid'}>
          {t('new_application.step2.InvalidDomain')}
        </Form.Control.Feedback>
      </Form.Group>

      {props.appInfo.has_database && (
        <>
          <h5 className="text-body-primary mt-5 mb-3">{t('new_application.step3.Database')}</h5>
          <ToggleButtonGroup
            type="radio"
            name="database"
            className="mb-2"
            value={props.appInfo.data_sources.length > 0 ? props.appInfo.data_sources[0].type : ''}
          >
            {DatabaseList.map((database) => (
              <ToggleButton
                key={database.id}
                id={database.id}
                value={database.id as DataSource}
                disabled={database.unavailable}
                onClick={(ev) => handleDatabaseChange(database.id)}
              >
                <span id={database.id} className={`fa fa-${database.icon}`} /> {database.name}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </>
      )}

      <Authorization allowedSystemAuthorities={[SystemRoleAuthorityName.ADDITIONAL_MODULES]}>
        <>
          <h5 className="text-body-primary mt-5 mb-3">
            {t('new_application.step3.AdditionalModules')}
          </h5>

          <ToggleButton
            id="assets-authentication-checkbox"
            className="me-3 mb-2 position-relative"
            type="checkbox"
            name="additionalmodules"
            value={additionalmodules[0].id}
            checked={props.appInfo.has_authentication}
            onChange={(e) => handleChange('has_authentication', e.currentTarget.checked)}
            style={
              props.appInfo.has_authentication === true
                ? { color: '#fff', border: 'none' }
                : {
                    backgroundColor: 'gray',
                    color: '#fff',
                    border: 'none'
                  }
            }
          >
            <span id={'authenticationButton'} className={`fa fa-${additionalmodules[0].icon}`} />{' '}
            {additionalmodules[0].name}
          </ToggleButton>

          <ToggleButton
            id="assets-option-checkbox"
            className="me-3 mb-2 position-relative"
            type="checkbox"
            name="additionalmodules"
            value={additionalmodules[1].id}
            checked={props.appInfo.has_assets}
            onChange={(e) => handleChange('has_assets', e.currentTarget.checked)}
            style={
              props.appInfo.has_assets === true
                ? { color: '#fff', border: 'none' }
                : {
                    backgroundColor: 'gray',
                    color: '#fff',
                    border: 'none'
                  }
            }
          >
            <span id={`assetsButton`} className={`fa fa-${additionalmodules[1].icon}`} />{' '}
            {additionalmodules[1].name}
          </ToggleButton>
        </>
      </Authorization>
    </>
  );
}

export default Step4;
