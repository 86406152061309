import React, { ChangeEvent, useEffect, useState } from 'react';
import { AppFullInfo } from 'modules/project/types';
import { ToggleButton } from 'react-bootstrap';
import { AppType, CustomApp } from 'modules/project/enum';
import { FullAppList, CustomAppList } from './data';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.css';

export type Step0Props = {
  /** Application info to use on the form */
  appInfo: AppFullInfo;
  /** Callback on form changes */
  onChange: <K extends keyof AppFullInfo>(key: K, value: AppFullInfo[K]) => void;
  /* set state of errors */
  updateErrorMessage: (s: string) => void;
};

export type AppTypeOption<T> = {
  id: T;
  /** Application type enum */
  type: T;
  /** Human-readable name to show on the UI */
  i18nKey: string;
  /** Icon of this application type */
  icon?: string;
  /** Disables this option and shows a "Soon" badge */
  unavailable?: boolean;
};

/**
 * Step0 of the application creation wizard.
 * In this step the user must select the type of application to build.
 *
 * New types can be added to `FullAppList` or `CustomAppList`.
 *
 * @component
 */
function Step0(props: Step0Props) {
  const { t } = useTranslation();
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const { updateErrorMessage } = props;

  useEffect(() => {
    const initialStudios = [];
    props.appInfo[CustomApp.FRONTEND] && initialStudios.push(CustomApp.FRONTEND);
    props.appInfo[CustomApp.BACKEND] && initialStudios.push(CustomApp.BACKEND);
    props.appInfo[CustomApp.DATABASE] && initialStudios.push(CustomApp.DATABASE);
    setSelectedOptions(initialStudios);
  }, []);

  useEffect(() => {
    selectedOptions.length < 1
      ? updateErrorMessage(t('inputValidationErrorMessages.StudioEmptyError'))
      : updateErrorMessage('');
  }, [selectedOptions, t, updateErrorMessage]);

  /**
   * Handles the application's type selection.
   */
  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    const type: AppType = e.currentTarget.value as AppType;
    props.onChange('type', type);
  }

  function handleChangeCustom(key: any) {
    const selecteds = [...selectedOptions];

    if (props.appInfo[key as CustomApp]) {
      if (selectedOptions.length > 1) {
        props.onChange(key, false);
        setSelectedOptions(selecteds.filter((s) => s !== key));
      }
    } else {
      props.onChange(key, true);
      setSelectedOptions([...selecteds, key]);
    }
  }

  const OptionToggle = (option: AppTypeOption<AppType>) => {
    return (
      <ToggleButton
        className="me-3 mb-2 position-relative"
        id={option.id}
        type="radio"
        name="type"
        variant={props.appInfo.type === option.type ? 'primary' : 'outline-secondary'}
        value={option.type}
        disabled={option.unavailable}
        checked={props.appInfo.type === option.type}
        onChange={handleChange}
      >
        <span className={`fa fa-${option.icon}`} /> {t(option.i18nKey)}
        {option.unavailable && (
          <span
            className={`position-absolute top-0 start-100 translate-middle badge rounded-pill bg-primary ${styles.commingSoon}`}
          >
            {t('Soon')}
          </span>
        )}
      </ToggleButton>
    );
  };

  return (
    <>
      <h4 className="text-body-emphasis">{t('new_application.step0.KindApplication')}</h4>

      <h5 className="text-body-primary mt-5 mb-3">{t('new_application.step0.ApplicationType')}</h5>
      {FullAppList.map((option) => (
        <OptionToggle key={option.id} {...option} />
      ))}

      <h5 className="text-body-primary mt-5 mb-3">{t('new_application.step0.Custom')}</h5>
      {CustomAppList.map((option) => (
        <ToggleButton
          id={option.id}
          key={option.id}
          className="me-3 mb-2 position-relative"
          type="radio"
          variant={props.appInfo[option.id] ? 'primary' : 'outline-secondary'}
          value={option.type}
          disabled={option.unavailable}
          checked={props.appInfo[option.id]}
          onClick={() => handleChangeCustom(option.id)}
        >
          <span id={option.id} className={`fa fa-${option.icon}`} /> {t(option.i18nKey)}
          {option.unavailable && (
            <span
              className={`position-absolute top-0 start-100 translate-middle badge rounded-pill bg-primary ${styles.commingSoon}`}
            >
              {t('Soon')}
            </span>
          )}
        </ToggleButton>
      ))}
    </>
  );
}

export default Step0;
