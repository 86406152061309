import React, { useState } from 'react';
import styles from './styles.module.css';
import { Badge } from 'react-bootstrap';
import { Endpoint, METHODS } from 'modules/logic_builder/types';
import { CrudData } from '..';
import EndpointEditor from './components/endpoint_edition';
import Icon from '../../../../../../web_ui/icon';
import { t } from 'i18next';
import HelpPopover from 'web_ui/workboard/sidebar/controls/components/Popover';
import CodeEditorModal from 'web_ui/code_editor_modal';
import { CodePreviewType } from 'web_ui/code_editor_modal/editor';
import { formatCrudType } from 'utils/crudUtils';

type CrudEndpointInstanceProps = {
  // tagUuid?: string;
  showEndpointEditorOnClick: boolean;
  // The full list of roles.
  roles?: string[];
  // Apply custom styles.
  className?: string;
  // enableDialog?: boolean;
  cursor?: string;
  endpoint: Endpoint;
  controllerPath?: string;
  crudData: CrudData;
  selectedControllerId?: string;
  onChange: (crudData: CrudData) => void;
};

type EndpointStyles = {
  badge: string;
  border: string;
  background: string;
};

export const ENDPOINT_COLORS: { [key: string]: EndpointStyles } = {
  [METHODS.POST]: {
    badge: 'PostBackgroundColor', // css classes because using values did not work with bootstrap
    border: '1px solid #198754',
    background: 'rgba(25, 135, 84, 0.1)'
  },
  [METHODS.PUT]: {
    badge: 'PutBackgroundColor',
    border: '1px solid #FFC107',
    background: 'rgba(255, 193, 7, 0.1)'
  },
  [METHODS.GET]: {
    badge: 'GetBackgroundColor',
    border: '1px solid #0DCAF0',
    background: 'rgba(13, 202, 240, 0.1)'
  },
  [METHODS.PATCH]: {
    badge: 'PatchBackgroundColor',
    border: '1px solid #BA38E8',
    background: 'rgba(186, 56, 232, 0.1)'
  },
  [METHODS.DELETE]: {
    badge: 'DeleteBackgroundColor',
    border: '1px solid #DC3545',
    background: 'rgba(220, 53, 69, 0.1)'
  }
};

export function CrudEndpointInstance(props: CrudEndpointInstanceProps) {
  const [showEndpointEditor, setShowEndpointEditor] = useState(false);
  const [showCodePreviewDialog, setShowCodePreviewDialog] = useState<boolean>(false);

  const handleShowEndpointEditor = () => {
    if (!props.showEndpointEditorOnClick) return;

    setShowEndpointEditor(!showEndpointEditor);
  };

  const deleteEndpointFromList = (endpoint: Endpoint) => {
    const indexToDelete = props.crudData.endpoints.indexOf(endpoint);
    const copyData = props.crudData.endpoints;

    copyData.splice(indexToDelete, 1);

    const data = {
      ...props.crudData,
      endpoints: copyData
    };

    props.onChange(data);
  };

  const renderPath = () => {
    const endpointPath = props.endpoint.path ?? '';
    const endpointController =
      props.crudData.controllers[
        props.crudData.controllers.findIndex((item) => item.uuid === props.endpoint.controllerUuid)
      ];
    let endpointControllerPath = '';
    if (endpointController) {
      endpointControllerPath = endpointController.path ?? '';
    }
    return endpointControllerPath + endpointPath;
  };

  return (
    <div
      className={`bg-body-tertiary border`}
      style={{ borderRadius: '7px', padding: 0, marginBottom: '1rem' }}
      translate="no"
    >
      <div
        id={props.endpoint.name}
        className={`${styles.EndpointWrapper} ${props.className}`}
        style={{
          background: ENDPOINT_COLORS[props.endpoint.method].background,
          border: ENDPOINT_COLORS[props.endpoint.method].border,
          cursor: props.cursor ?? 'default',
          borderRadius: '5px'
        }}
      >
        <div className={styles.wrapMethodAndPath} id={props.endpoint.path}>
          <div
            className={`${styles.EndpointDescription} ${props.roles && styles.EndpointDescription}`}
            style={{ marginRight: '0.8rem' }}
          >
            <Badge
              className={`${styles.Badge} ${styles[ENDPOINT_COLORS[props.endpoint.method].badge]}`}
            >
              {props.endpoint.method}
            </Badge>
          </div>
          <div className={styles.containerPathDesc}>{renderPath()}</div>
          <div className={styles.wrapperIcons}>
            <div
              className={`${styles.Details}`}
              style={{ color: `${ENDPOINT_COLORS[props.endpoint.method].border.split(' ')[2]}` }}
            >
              <div className={styles.footerTag}>{formatCrudType(props.endpoint.crudType)}</div>
            </div>
            <div className={styles.BadgeNew}>{t('New')}</div>
            {/* Retirado o botão de code Preview, porque estes endpoints ainda são prototipo e ainda não foram geradas */}
            {/* <HelpPopover
              placement={'top'}
              helpBoxProps={{
                title: t('CodePreview') ?? 'CodePreview'
              }}
            >
              <div
                id="codePreview"
                className={`bg-body-tertiary shadow-sm ${styles.itemIcons}`}
                onClick={handleShowCodePreviewDialog}
              >
                <Icon iconName="fa-solid fa-code" extraProps="text-secondary h5 pt-2" />
              </div>
            </HelpPopover> */}
            <HelpPopover
              placement={'top'}
              helpBoxProps={{
                title: t('editButton')!
              }}
            >
              <div
                id={`editButton-${props.endpoint.name}`}
                className={`bg-body-tertiary shadow-sm ${styles.itemIcons}`}
                onClick={handleShowEndpointEditor}
              >
                <Icon iconName="pen-to-square" extraProps="text-secondary h5 pt-2" />
              </div>
            </HelpPopover>
            <HelpPopover
              placement={'top'}
              helpBoxProps={{
                title: t('Delete')!
              }}
            >
              <div
                id={`deleteButton-${props.endpoint.name}`}
                className={`bg-body-tertiary shadow-sm ${styles.itemIcons}`}
                onClick={() => deleteEndpointFromList(props.endpoint)}
              >
                <Icon iconName="trash" extraProps="text-secondary h5 pt-2" />
              </div>
            </HelpPopover>
          </div>
        </div>
      </div>
      {showEndpointEditor &&
        props.crudData.endpoints[props.crudData.endpoints.indexOf(props.endpoint)] && (
          <EndpointEditor
            crudData={props.crudData}
            onChange={props.onChange}
            currentEndpoint={props.endpoint}
          />
        )}
      {props.endpoint.uuid && (
        <CodeEditorModal
          show={showCodePreviewDialog}
          handleClose={() => setShowCodePreviewDialog(false)}
          id={props.endpoint.uuid}
          previewType={CodePreviewType.ENDPOINT}
        />
      )}
    </div>
  );
}
