import React, { useEffect, useState } from 'react';
import styles from './styles.module.css';
import { Badge } from 'react-bootstrap';
import { FunctionExtended, FunctionType } from 'modules/logic_builder/types';
import { useNavigate, useParams } from 'react-router-dom';
import { functionEditorCrud } from 'web_ui/function_editor/store/types/functions';
import { t } from 'i18next';
import { CrudData } from 'routes/automation_wizard/components/wizard_steps/crud';
import HelpPopover from 'web_ui/workboard/sidebar/controls/components/Popover';
import { useQuery } from 'hooks/useQuery';
import useSession from 'hooks/useSession';
import CodeEditorModal from 'web_ui/code_editor_modal';
import { CodePreviewType } from 'web_ui/code_editor_modal/editor';
import Confirmation from 'web_ui/confirmation';
import { FunctionService } from 'modules/logic_builder/services';
import { formatCrudType } from 'utils/crudUtils';

type FunctionInstanceProps = {
  serviceUuid: string;
  // Apply custom styles.
  className?: string;
  cursor?: string;
  functionInstance: functionEditorCrud | FunctionExtended | FunctionType;
  crudData: CrudData;
  onlyOpenWindow?: boolean;
  getFuncList?: (serviceId: string, funcIdDelete?: string) => void;
};

export function FunctionInstance(props: FunctionInstanceProps) {
  const queryParameters = useQuery();
  const [returnNameType, setReturnNameType] = useState<string>('');
  const [functionName, setFunctionName] = useState<string>('');
  const [functionParameters, setFunctionParameters] = useState<string>('');
  const [previewFunctionUuid, setPreviewFunctionUuid] = useState<string>('');
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const codeRef = React.createRef<HTMLDivElement>();
  const [session] = useSession();

  const { app_id, service_id, module_id } = useParams();
  const navigate = useNavigate();

  async function handleDelete(event: React.MouseEvent<any>) {
    event.stopPropagation();
    setShowConfirmationDialog(true);
  }

  async function onDelete() {
    if (!module_id) return;
    await FunctionService.deleteFunction(props.functionInstance.uuid!).then(() => {
      if (props.getFuncList) {
        props.getFuncList(props.functionInstance.serviceUuid, props.functionInstance.uuid!);
        setShowConfirmationDialog(false);
      }
    });
  }

  const isFromVsCodeExt = (): boolean => {
    const itemFound = queryParameters.get('vscode');
    if (itemFound) {
      return Boolean(itemFound);
    } else {
      return false;
    }
  };

  async function handleShowFunctionEditorDialog(
    event: React.MouseEvent<any>,
    openNewWindow?: boolean
  ) {
    if (!app_id || !module_id) return;
    event.stopPropagation();
    const lnk = isFromVsCodeExt()
      ? `/app/${app_id}/module/${module_id}/logic/service/${service_id}/function-editor/${props.functionInstance.uuid}?vscode=true`
      : `/app/${app_id}/module/${module_id}/logic/service/${service_id}/function-editor/${props.functionInstance.uuid}`;
    if (openNewWindow || props.onlyOpenWindow) {
      window.open(lnk, '_blank');
    } else {
      navigate(lnk, { replace: true });
    }
  }

  useEffect(() => {
    const returned = props.functionInstance.functionDefinition;
    const returnName = returned.split(' ');
    setReturnNameType(returnName[0]);
    returnName.shift();
    const functionName = returnName.toString();
    setFunctionName(functionName.split('(')[0]);
    setFunctionParameters('(' + functionName.split('(')[1].replaceAll(',', ' '));
  }, [props.crudData?.functions]);

  function isFunctionEditorCrud(obj: any): obj is functionEditorCrud {
    return 'crudType' in obj;
  }

  return (
    <>
      <div
        className={`bg-body-tertiary border ${styles.FunctionWrapper} ${props.className}`}
        style={{
          cursor: props.cursor ?? 'default'
        }}
        onClick={(e) => handleShowFunctionEditorDialog(e)}
        translate="no"
      >
        <div id={props.functionInstance.description} className={`${styles.FunctionDescription}`}>
          <Badge
            className={`${styles.Badge} ${styles.BadgeBackground}`}
            bg={session.preferences['exocode-theme'] ? '#1c2025' : '#FFFFFF'}
          >
            <div ref={codeRef} className={styles.CodeFunctionDefinition}>
              <div
                className={styles.CodeDefinition}
                style={
                  props.functionInstance.functionDefinition
                    ? props.functionInstance.functionDefinition.includes('-') ||
                      props.functionInstance.functionDefinition.includes('_')
                      ? { color: '#DCDCAA' }
                      : {}
                    : {}
                }
              />
              <span style={{ color: '#8E619A' }}>{returnNameType} </span>
              <span style={{ color: '#CFCC9B' }}>{functionName}</span>
              <span style={{ color: '#63A189' }}>{functionParameters}</span>
            </div>
            <div className={`${styles.Details}`}>
              {isFunctionEditorCrud(props.functionInstance) && (
                <div className={styles.BadgeFunction}>
                  {formatCrudType(props.functionInstance.crudType)}
                </div>
              )}
              <HelpPopover
                placement={'top'}
                helpBoxProps={{
                  title: t('CodePreview') ?? 'CodePreview'
                }}
              >
                <div
                  id="codePreview"
                  className={`btn btn-sm text-body-secondary bg-body-secondary border-0`}
                  style={{ marginLeft: '10px' }}
                  onClick={(event) => {
                    event.stopPropagation();
                    setPreviewFunctionUuid(props.functionInstance.uuid ?? '');
                  }}
                >
                  <span className={'fa fa-code'} />
                </div>
              </HelpPopover>
              <HelpPopover
                helpBoxProps={{
                  title: t('OpenNewWnd') ?? ''
                }}
                placement="top"
              >
                <div
                  className={`btn btn-sm text-body-secondary bg-body-secondary border-0`}
                  style={{ marginLeft: '10px' }}
                  onClick={(e) => handleShowFunctionEditorDialog(e, true)}
                >
                  <span className={'fa fa-arrow-up-right-from-square'} />
                </div>
              </HelpPopover>
              <HelpPopover
                helpBoxProps={{
                  title: t('Delete') ?? ''
                }}
                placement="top"
              >
                <div
                  className={`btn btn-sm text-body-secondary bg-body-secondary border-0`}
                  style={{ marginLeft: '10px' }}
                  onClick={handleDelete}
                >
                  <span className={'fa fa-trash'} />
                </div>
              </HelpPopover>
            </div>
          </Badge>
        </div>
      </div>
      <CodeEditorModal
        show={previewFunctionUuid !== ''}
        handleClose={() => setPreviewFunctionUuid('')}
        id={previewFunctionUuid}
        previewType={CodePreviewType.FUNCTION}
      />
      <Confirmation
        show={showConfirmationDialog}
        message={`${t('deleteQuotes.function')}`}
        onConfirmation={onDelete}
        onCancel={() => setShowConfirmationDialog(false)}
        onClose={() => setShowConfirmationDialog(false)}
      />
    </>
  );
}
