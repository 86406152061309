import React, { useEffect, useState } from 'react';
import { CrudData } from './index';
import { Trans, useTranslation } from 'react-i18next';
import styles from './styles.module.css';
import { Dropdown, DropdownButton, Spinner } from 'react-bootstrap';
import Icon from '../../../../../web_ui/icon';
import { DashboardService, ObjectsService } from '../../../../../modules/logic_builder/services';
import { useParams } from 'react-router-dom';
import { ObjectDialog } from 'modules/logic_builder/components/dialogs/object_dialog';
import { Column, EnumFrame, Relationship, Table } from 'modules/modeler/types';
import { ModuleInfo } from '../../../../../modules/dashboard/types';
import './dropdown.css';
import HelpPopover from 'web_ui/workboard/sidebar/controls/components/Popover';
import { fetchEntities } from 'routes/automation_wizard/helpers/fetchEntities';
import { ObjectSimple } from 'modules/logic_builder/types';
import { ObjectInstance } from 'modules/logic_builder/components/objects_list/object_instance';
import CodeEditorModal from 'web_ui/code_editor_modal';
import { CodePreviewType } from 'web_ui/code_editor_modal/editor';

type Step2Props = {
  crudData: CrudData;
  onChange: (crudData: CrudData) => void;
  setFormValidity: (validity: boolean) => void;
  setUUIDCreated: React.Dispatch<React.SetStateAction<string[]>>;
  loading?: (val: boolean) => void;
};

export function Step2Objects(props: Step2Props) {
  const { t } = useTranslation();
  const { app_id, module_id } = useParams();
  const [showObjectEditor, setShowObjectEditor] = useState<boolean>(false);
  const [objectEditUuid, setObjectEditUuid] = useState<string>();
  const [modules, setModules] = useState<ModuleInfo[]>([]);
  const [tables, setTables] = useState<Table[]>([]);
  const [columns, setColumns] = useState<Column[]>([]);
  const [relationships, setRelationships] = useState<Relationship[]>([]);
  const [enums, setEnums] = useState<EnumFrame[]>([]);
  const [objectsWithDeps, setObjectsWithDeps] = useState<ObjectSimple[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [previewObjectUuid, setPreviewObjectUuid] = useState<string>('');

  useEffect(() => {
    if (props.crudData.objects) {
      setIsLoading(false);
    }
  }, [props.crudData.objects]);

  const sortItems = (a: string, b: string) => {
    const extractNumber = (name: string) => {
      const match = name.match(/_(\d+)$/); // Captura o número após o "_"
      return match ? parseInt(match[1], 10) : 0;
    };

    return extractNumber(a) - extractNumber(b);
  };

  async function getEntityObjects(UUIDsCreated?: string[]) {
    props.loading && props.loading(true);
    if (UUIDsCreated) props.setUUIDCreated((val) => [...val, UUIDsCreated[0]]);
    if (!module_id || !props.crudData || !props.crudData.entityUuid) return;

    const objectList = await ObjectsService.getObjectsByEntity(
      module_id,
      props.crudData.entityUuid
    );

    const entities = await fetchEntities(module_id);

    const crud = {
      ...props.crudData,
      entities: entities,
      objects: objectList.sort((a, b) => sortItems(a.name, b.name))
    } as CrudData;

    if (props.crudData.objects.length === 0) {
      props.setFormValidity(false);
    } else {
      props.setFormValidity(true);
    }

    props.loading && props.loading(false);
    props.onChange(crud);
  }

  async function handleBaseEntity() {
    props.loading && props.loading(true);
    if (
      !module_id ||
      !props.crudData ||
      !props.crudData.entityUuid ||
      Object.keys(props.crudData.entities).length <= 0
    )
      return;
    ObjectsService.createBaseObject(module_id, props.crudData.entityUuid!).then((createdObj) => {
      const uuidObj: string[] = [createdObj.uuid];
      getEntityObjects(uuidObj);
    });
    props.loading && props.loading(false);
  }

  useEffect(() => {
    getEntityObjects();
  }, []);

  function handleCustomize() {
    setShowObjectEditor(true);
  }

  useEffect(() => {
    if (!module_id) return;
    const fetchSchema = async () => {
      const schema = await ObjectsService.getObjectsSchema(module_id, true);
      setEnums(schema.enums && Object.keys(schema).length !== 0 ? schema.enums : []);
      setTables(schema.tables && Object.keys(schema).length !== 0 ? schema.tables : []);
      setColumns(schema.columns && Object.keys(schema).length !== 0 ? schema.columns : []);
      setRelationships(
        schema.relationships && Object.keys(schema).length !== 0 && schema.relationships
          ? schema.relationships
          : []
      );
    };
    fetchSchema();
  }, [module_id]);

  useEffect(() => {
    if (!app_id) return;
    DashboardService.getModulesByApp(app_id).then((modules) => setModules(modules));
  }, [app_id]);

  useEffect(() => {
    if (props.crudData.objects.length > 0) {
      props.setFormValidity(true);
    } else {
      props.setFormValidity(false);
    }
  }, [props.crudData.objects]);

  async function fetchObjectWithDeps() {
    if (!module_id) return;
    await ObjectsService.getObjectsByModuleWithDependencies(module_id).then((fetchedObj) => {
      setObjectsWithDeps(fetchedObj);
    });
  }

  useEffect(() => {
    fetchObjectWithDeps();
  }, [props.crudData.objects]);

  return (
    <div className={styles.StepWrapper}>
      <div id="headerMessage" className={styles.Title}>
        {t('automation.Step2Title')}
        <HelpPopover
          helpBoxProps={{
            title: t('automation.Step2Tooltip') ?? ''
          }}
          placement="right"
        >
          <span>
            <Icon padding={'10px'} brands={'regular'} iconName={'circle-question'} />
          </span>
        </HelpPopover>
      </div>
      {/* Data Objects */}
      <div className={styles.DataObjectsHeader}>
        <div style={{ width: 'fit-content' }}>{t('automation.step1.dataObjects')}:</div>
        <HelpPopover
          placement={'top'}
          helpBoxProps={{
            title: t('CreateButton')!
          }}
        >
          <DropdownButton
            id="createButton"
            variant="primary"
            autoClose={true}
            drop={'down'}
            style={{ display: 'flex' }}
            title={t('automation.step2.AddObject')}
          >
            <Dropdown.Item id={'baseEntityButton'} onClick={handleBaseEntity}>
              <Icon iconName="list-ul" padding={'5px'} />
              {t('automation.step1.baseEntity')}
            </Dropdown.Item>
            <Dropdown.Item id={'customObjectButton'} onClick={handleCustomize}>
              <Icon iconName="list-check" padding={'5px'} />
              {t('automation.step1.custom')}
            </Dropdown.Item>
          </DropdownButton>
        </HelpPopover>
      </div>
      {/* Data Objects List*/}
      {isLoading ? (
        <div className="d-flex justify-content-center">
          <Spinner animation="border" variant="secondary" />
        </div>
      ) : Object.values(props.crudData.objects).length > 0 ? (
        <div id="list-group" className={styles.ObjectListWrapper}>
          {Object.values(props.crudData.objects).map((object) => {
            return (
              <ObjectInstance
                key={object.uuid}
                cursor="pointer"
                objectInstance={object}
                onEdit={(e: ObjectSimple) => setObjectEditUuid(e.uuid)}
                onPreviewObject={(o: string) => setPreviewObjectUuid(o)}
                tables={tables}
                relationships={relationships}
                columns={columns}
                enums={enums}
                allObjects={props.crudData.objects}
                loadingData={false}
                modules={modules}
                objectsWithDeps={objectsWithDeps}
                getObj={getEntityObjects}
              />
            );
          })}
        </div>
      ) : (
        <div id="bodyMessage" className={styles.emptyMessage} translate="no">
          <Trans
            i18nKey="automation.Step2NoObjects"
            components={{
              1: <a href="#" id="addBaseEntityLink" onClick={handleBaseEntity} />,
              2: <a href="#" id="addCustomLink" onClick={handleCustomize} />
            }}
          />
        </div>
      )}
      <CodeEditorModal
        show={previewObjectUuid !== ''}
        handleClose={() => setPreviewObjectUuid('')}
        id={previewObjectUuid}
        previewType={CodePreviewType.OBJECT}
      />
      {/* Custom object editor */}
      <ObjectDialog
        show={showObjectEditor}
        onClose={() => {
          setShowObjectEditor(false);
          setObjectEditUuid(undefined);
        }}
        editMode={!!objectEditUuid}
        dialogTitle={objectEditUuid ? 'EditObject' : 'CreateObject'}
        objectId={objectEditUuid}
        modules={modules}
        tables={tables}
        columns={columns}
        enums={enums}
        relationships={relationships}
        entityMode={false}
        preselectedEntity={props.crudData.entityUuid}
        crudEntityList={Object.values(props.crudData.entities)}
        gettingObjects={getEntityObjects}
        objectsWithDeps={objectsWithDeps}
        crudData={props.crudData}
      />
    </div>
  );
}
