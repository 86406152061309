import React, { useEffect, useState } from 'react';
import styles from './styles.module.css';
import { Badge, Button, Form } from 'react-bootstrap';
import useWindowDimensions from 'modules/designer/hooks/window';
import hljs from 'highlight.js';
import 'highlight.js/styles/night-owl.css';
import java from 'highlight.js/lib/languages/java';
import Icon from 'web_ui/icon';
import Confirmation from 'web_ui/confirmation';
import { useDispatch, useSelector } from 'react-redux';
import { InterfaceStudioState } from '../../store';
import { deleteFunction } from '../../store/actions/functions';
import { useNavigate, useParams } from 'react-router-dom';
import { FunctionEditorState } from 'web_ui/function_editor/store/types/function_editor_state';
import CreateFunctionDialog from '../../components/create_function_dialog';
import { t } from 'i18next';
import { useQuery } from 'hooks/useQuery';
import HelpPopover from 'web_ui/workboard/sidebar/controls/components/Popover';
import CodeEditorModal from 'web_ui/code_editor_modal';
import { CodePreviewType } from 'web_ui/code_editor_modal/editor';
import useSession from 'hooks/useSession';

hljs.registerLanguage('java', java);

const FunctionInstanceItem = ({
  functionId,
  functionName,
  functionDescription,
  returnNameType,
  functionParameters
}: {
  functionId: string;
  functionName: string;
  functionDescription: string;
  returnNameType: string;
  functionParameters: string;
}) => {
  const navigate = useNavigate();
  const queryParameters = useQuery();
  const dispatch = useDispatch();
  const windowDimensions = useWindowDimensions();
  const [numberOfCommentLines, setNumberOfCommentLines] = useState(0);
  const { app_id, module_id } = useParams();
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [mouseHovering, setMouseHovering] = useState(false);
  const [previewFunctionUuid, setPreviewFunctionUuid] = useState<string>('');
  const functionCommentRef = React.useRef<HTMLDivElement>(null);
  const [session] = useSession();
  const viewId = useSelector((state: InterfaceStudioState) => state.studio.view_id);

  const isFromVsCodeExt = (): boolean => {
    const itemFound = queryParameters.get('vscode');
    if (itemFound) {
      return Boolean(itemFound);
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (functionCommentRef.current && windowDimensions.width) {
      setNumberOfCommentLines(Math.round(functionCommentRef.current.clientHeight / 16));
    }
  }, [functionCommentRef, windowDimensions.width]);

  const deleteFn = () => {
    if (!functionId || !viewId) return;
    dispatch(deleteFunction(viewId, functionId));
    setShowConfirmationDialog(false);
  };

  const handleDeleteFunction = (event: React.MouseEvent<HTMLDivElement>): void => {
    event.stopPropagation();
    setShowConfirmationDialog(true);
  };

  const handleOpenFunctionEditor = (event: React.MouseEvent<HTMLDivElement>): void => {
    if (!app_id || !module_id || !viewId || !functionId) return;
    event.stopPropagation();
    navigate(
      isFromVsCodeExt()
        ? `/app/${app_id}/module/${module_id}/ui/${viewId}/function-editor/${functionId}?vscode=true`
        : `/app/${app_id}/module/${module_id}/ui/${viewId}/function-editor/${functionId}`,
      {
        replace: true
      }
    );
  };

  const code = hljs.highlight(`${functionName}`, {
    language: 'typescript'
  }).value;

  return (
    <>
      <div
        className={`border ${styles.FunctionWrapper}`}
        style={{
          backgroundColor: mouseHovering
            ? 'rgba(62, 71, 77, 1) !important'
            : session.preferences['exocode-theme']
              ? '#1c2025'
              : '#FFFFFF'
        }}
        onMouseEnter={() => setMouseHovering(true)}
        onMouseLeave={() => setMouseHovering(false)}
        onClick={handleOpenFunctionEditor}
        translate="no"
      >
        <div className={`${styles.FunctionDescription}`}>
          {functionDescription && (
            <div className={`${styles.FunctionComment}`}>
              <div className={`${styles.CommentSlashes}`}>
                {Array(numberOfCommentLines)
                  .fill(0)
                  .map((index) => {
                    return (
                      <React.Fragment key={index}>
                        &#47;&#47;
                        <br />
                      </React.Fragment>
                    );
                  })}
              </div>
              <div className={styles.Details} ref={functionCommentRef}>
                {functionDescription}
              </div>
            </div>
          )}
          <div className={`${styles.Badge} ${styles.BadgeBackground}`}>
            <span style={{ color: '#8E619A' }}>{returnNameType}</span>
            <span style={{ color: '#CFCC9B' }}>{functionName}</span>
            <span style={{ color: '#63A189' }}>{functionParameters}</span>
          </div>
        </div>
        <div className={styles.wrapperIcons}>
          <HelpPopover
            placement={'top'}
            helpBoxProps={{
              title: t('CodePreview') ?? 'CodePreview'
            }}
          >
            <div
              id={`codePreview-${functionName}`}
              className={`bg-body-tertiary shadow-sm ${styles.itemIcons}`}
              onClick={(event) => {
                event.stopPropagation();
                setPreviewFunctionUuid(functionId ?? '');
              }}
            >
              <Icon iconName="fa fa-code" extraProps={`text-secondary h7`} />
            </div>
          </HelpPopover>
          <HelpPopover
            placement={'top'}
            helpBoxProps={{
              title: t('editButton') ?? 'editButton'
            }}
          >
            <div
              id={`editButton-${functionName}`}
              className={`bg-body-tertiary shadow-sm ${styles.itemIcons}`}
              onClick={handleOpenFunctionEditor}
            >
              <Icon iconName="pen-to-square" extraProps={`text-secondary h7`} />
            </div>
          </HelpPopover>
          <HelpPopover
            placement={'top'}
            helpBoxProps={{
              title: t('Delete') ?? 'Delete'
            }}
          >
            <div
              id={`deleteButton-${functionName}`}
              className={`bg-body-tertiary shadow-sm ${styles.itemIcons}`}
              onClick={handleDeleteFunction}
            >
              <Icon iconName="trash" extraProps={`text-secondary h7`} />
            </div>
          </HelpPopover>
        </div>
      </div>
      <CodeEditorModal
        show={previewFunctionUuid !== ''}
        handleClose={() => setPreviewFunctionUuid('')}
        id={previewFunctionUuid}
        previewType={CodePreviewType.FUNCTION}
      />
      <Confirmation
        show={showConfirmationDialog}
        message={`${t('deleteQuotes.function') ?? ''} (${functionName})`}
        onCancel={() => setShowConfirmationDialog(false)}
        onConfirmation={deleteFn}
        onClose={() => setShowConfirmationDialog(false)}
      />
    </>
  );
};

export function FunctionInstance() {
  const functions = useSelector((state: FunctionEditorState) => state.functions);
  const viewId = useSelector((state: InterfaceStudioState) => state.studio.view_id);
  const [showCreateFunctionDialog, setShowCreateFunctionDialog] = useState(false);
  const [search, setSearch] = useState('');

  const functionListItems = (): JSX.Element[] => {
    return Object.values(functions).map((f) => {
      const returnNameType = f.returnType?.type || 'void';
      const functionName = f.name.split(' ')[0];
      const parameters = `(${f.parameters?.length > 0 ? f.parameters.toString : ''})`;

      return (
        <FunctionInstanceItem
          key={f.uuid}
          functionId={f.uuid}
          functionName={functionName}
          functionDescription={f.desc}
          returnNameType={returnNameType}
          functionParameters={parameters}
        />
      );
    });
  };

  return (
    <>
      <div className={styles.FilterInput}>
        <Form className={styles.FilterInputForm}>
          <Form.Control
            id={'searchField'}
            type="search"
            placeholder="Search"
            aria-label="Search"
            onChange={(e) => setSearch(e.target.value)}
            value={search}
          />
        </Form>
        <div>
          <Button
            className="me-3"
            id={'createButton'}
            variant="primary"
            onClick={() => setShowCreateFunctionDialog(true)}
          >
            <Icon iconName="plus" extraProps="pe-1"></Icon>
            {t('organizations.new_organization.Create')}
          </Button>
        </div>
      </div>

      <div className={styles.Content}>{functionListItems()}</div>
      {viewId && (
        <CreateFunctionDialog
          view_id={viewId}
          show={showCreateFunctionDialog}
          onClose={() => setShowCreateFunctionDialog(false)}
        />
      )}
    </>
  );
}
