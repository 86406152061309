import React, { MouseEvent, useCallback, useState } from 'react';
import styles from './styles.module.css';
import { Badge, Button, Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { InterfaceStudioState } from '../../store';
import Confirmation from 'web_ui/confirmation';
import { useParams } from 'react-router-dom';
import Icon from 'web_ui/icon';
import { FrontendProperty } from 'modules/designer/types';
import { useTranslation } from 'react-i18next';
import { ManagePropertyDialog } from '../../components/manage_property_dialog';
import { deleteProperty } from '../../store/actions/properties';

const PropertyInstanceItem = ({
  propertyId,
  propertyName,
  propertyInfo
}: {
  propertyId: string;
  propertyName: string;
  propertyInfo: FrontendProperty;
}) => {
  const dispatch = useDispatch();
  const { view_id, custom_component_id } = useParams();
  let view: string;
  if (view_id) {
    view = view_id;
  } else if (custom_component_id) {
    view = custom_component_id;
  } else {
    view = '';
  }
  const [showPropertyDialog, setShowPropertyDialog] = useState(false);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

  const deleteProp = () => {
    if (!view) return;
    dispatch(deleteProperty(propertyId));
    setShowConfirmationDialog(false);
  };

  const handleEditProperty = () => {
    setShowPropertyDialog(true);
  };

  const handleDeleteProperty = (event: MouseEvent<HTMLSpanElement>) => {
    event.stopPropagation();
    setShowConfirmationDialog(true);
  };

  return (
    <>
      <div
        className={`border bg-body-tertiary ${styles.VariableWrapper}`}
        style={{ cursor: 'pointer' }}
        onClick={handleEditProperty}
      >
        <div className={`${styles.VariableDescription}`}>
          <div className={styles.VariableLeftSideWrapper}>
            <Badge className={`${styles.VariableBadge} ${styles.VariableBadgeBackground}`}>
              {propertyName}
            </Badge>
          </div>
        </div>
        <span className={`${styles.TrashIconButtons} ms-2`} onClick={handleDeleteProperty}>
          <Icon iconName="trash"></Icon>
        </span>
      </div>
      {view && (
        <ManagePropertyDialog
          viewId={view}
          show={showPropertyDialog}
          onClose={() => setShowPropertyDialog(false)}
          property={propertyInfo}
          editMode={true}
        />
      )}
      {showConfirmationDialog && (
        <Confirmation
          show={showConfirmationDialog}
          // TODO: add translation.
          message="Delete Property"
          onCancel={() => setShowConfirmationDialog(false)}
          onConfirmation={deleteProp}
          onClose={() => setShowConfirmationDialog(false)}
        />
      )}
    </>
  );
};

export function PropertyInstance() {
  const { t } = useTranslation();
  const [search, setSearch] = useState('');
  const { view_id, custom_component_id } = useParams();
  let view: string;
  if (view_id) {
    view = view_id;
  } else if (custom_component_id) {
    view = custom_component_id;
  } else {
    view = '';
  }
  const properties = useSelector((state: InterfaceStudioState) => state.properties);
  const objects = useSelector((state: InterfaceStudioState) => state.objects);
  const [showCreatePropertyDialog, setShowCreatePropertyDialog] = useState(false);

  const filterProperties = (property: FrontendProperty): boolean => {
    if (property.name.toLowerCase().includes(search.toLowerCase())) return true;
    if (property.type.toLowerCase().includes(search.toLowerCase())) return true;
    return false;
  };

  const getObjectName = useCallback(
    (objectId: string) => {
      if (!objects[objectId]) return '';
      return objects[objectId].name ?? '';
    },
    [objects]
  );

  const getPropertySignature = (property: FrontendProperty): string => {
    let listSymbol = '';
    if (property.list) listSymbol = '[ ]';
    let typeSymbol = '';
    if (property.type === 'OBJECT') typeSymbol = getObjectName(property.object || '');
    else if (property.type) typeSymbol = property.type;
    return String(property.name) + ': ' + typeSymbol + listSymbol;
  };

  const propertyListItems = () => {
    return Object.values(properties)
      .filter((prop) => filterProperties(prop))
      .map((prop) => {
        return (
          <PropertyInstanceItem
            key={prop.uuid}
            propertyId={prop.uuid}
            propertyName={getPropertySignature(prop)}
            propertyInfo={prop}
          />
        );
      });
  };

  return (
    <>
      <div className={styles.FilterInput} translate="no">
        <Form className={styles.FilterInputForm}>
          <Form.Control
            id={'designer-behavior-searchinput-property'}
            type="search"
            placeholder="Search"
            aria-label="Search"
            onChange={(e) => setSearch(e.target.value)}
            value={search}
          />
        </Form>
        <div>
          <Button
            className="me-3"
            id={'designer-behavior-create-property'}
            variant="primary"
            onClick={() => setShowCreatePropertyDialog(true)}
          >
            <Icon iconName="plus" extraProps="pe-1"></Icon>
            {t('organizations.new_organization.Create')}
          </Button>
        </div>
      </div>

      <div className={styles.Content}>{propertyListItems()}</div>

      {view && (
        <ManagePropertyDialog
          viewId={view}
          show={showCreatePropertyDialog}
          onClose={() => setShowCreatePropertyDialog(false)}
          editMode={false}
        />
      )}
    </>
  );
}
