import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, ListGroup } from 'react-bootstrap';
import Icon from '../../../../web_ui/icon';
import { ModuleInfo } from 'modules/dashboard/types';
import EditModuleModal from '../../../../modules/project/components/edit_module_modal';
import { Authorization } from 'modules/auth/session/authorization';
import { ContextRole, RoleAuthorities } from 'modules/auth/types/auth_types';
import HelpPopover from 'web_ui/workboard/sidebar/controls/components/Popover';
import styles from './styles.module.css';
import { AppContext } from 'modules/project/store/app_context';
import { WALKTHROUGH_STEPS_ELEMENTS } from 'web_ui/walkthrough/constants';
import HelpIcon from '../../../../web_ui/workboard/sidebar/controls/components/HelpIcon';

export type ModuleProps = {
  module: ModuleInfo;
  modules: ModuleInfo[];
  // Call this on edit module modal close.
  onRefresh: () => void;
};

function Module(props: ModuleProps) {
  const [showEditModuleModal, setShowEditModuleModal] = React.useState(false);
  const [moduleInfo, setModuleInfo] = React.useState(props.module);
  const { t } = useTranslation();
  const appInfo = useContext(AppContext).projectInformation;
  const moduleLinkTo = moduleInfo.disabled
    ? undefined
    : appInfo?.has_database
    ? `/app/${moduleInfo.appId}/module/${moduleInfo.id}/schema/829fdea7-75fd-4202-9d0c-d237567ef52f`
    : appInfo?.has_backend
    ? `/app/${moduleInfo.appId}/module/${moduleInfo.id}/logic`
    : appInfo?.has_frontend && `/app/${moduleInfo.appId}/module/${moduleInfo.id}/ui`;

  const updateModule = (module: ModuleInfo) => {
    setModuleInfo(module);
  };

  return (
    <>
      <ListGroup.Item
        id={moduleInfo.name}
        className={`${styles.ModuleItem} ${props.module.disabled && styles.disabledRow}`}
        action
        variant="body"
      >
        <Link
          to={moduleLinkTo || ''}
          className={`${moduleInfo.isActive ? 'text-body-primary ' : 'text-body-secondary'} ${
            styles.moduleInfo
          }`}
          translate="no"
        >
          <Icon iconName="folder" />
          <span className={`text-body-emphasis ${styles.OverflowEllipsis}`}>
            {moduleInfo.name} {moduleInfo.isAssets && '(Assets)'}
          </span>
        </Link>
        <div
          className={styles.moduleButtons}
          id={WALKTHROUGH_STEPS_ELEMENTS['dashboard-module-buttons']}
        >
          {appInfo?.has_database && !moduleInfo.disabled && (
            <Authorization
              context={ContextRole.APP}
              allowedAuthorities={[RoleAuthorities.MANAGE_DB_MODELER]}
            >
              <HelpPopover
                placement="top"
                helpBoxProps={{
                  title: t('appResume.OpenDBStudio') ?? ''
                }}
              >
                <Button
                  id={'DB-Button'}
                  variant="body"
                  onClick={() =>
                    (window.location.href = `/app/${moduleInfo.appId}/module/${moduleInfo.id}/schema/829fdea7-75fd-4202-9d0c-d237567ef52f`)
                  }
                  className={`${styles.editModalButton} ${styles.moduleActionButton} btn-body`}
                >
                  <Icon iconName="database" />
                </Button>
              </HelpPopover>
            </Authorization>
          )}

          {appInfo?.has_backend && !moduleInfo.disabled && (
            <Authorization
              context={ContextRole.APP}
              allowedAuthorities={[RoleAuthorities.MANAGE_LOGIC_BUILDER]}
            >
              <HelpPopover
                placement="top"
                helpBoxProps={{
                  title: t('appResume.OpenLogicBuilder') ?? ''
                }}
              >
                <Button
                  id={'LB-Button'}
                  variant="body"
                  onClick={() =>
                    (window.location.href = `/app/${moduleInfo.appId}/module/${moduleInfo.id}/logic`)
                  }
                >
                  <Icon iconName="code-branch" />
                </Button>
              </HelpPopover>
            </Authorization>
          )}

          {appInfo?.has_frontend && !moduleInfo.disabled && (
            <Authorization
              context={ContextRole.APP}
              allowedAuthorities={[RoleAuthorities.MANAGE_INTERFACE]}
            >
              <HelpPopover
                helpBoxProps={{
                  title: `${t('appResume.OpenUIStudio')}`
                }}
                placement="top"
              >
                <Button
                  id={'UI-Button'}
                  variant="body"
                  onClick={() =>
                    (window.location.href = `/app/${moduleInfo.appId}/module/${moduleInfo.id}/ui`)
                  }
                >
                  <Icon iconName="window-maximize" />
                </Button>
              </HelpPopover>
            </Authorization>
          )}

          <Authorization
            context={ContextRole.APP}
            allowedAuthorities={[RoleAuthorities.MANAGE_MODULE]}
          >
            <HelpPopover
              helpBoxProps={{
                title: t('appResume.module.EditModule') ?? 'appResume.module.EditModule'
              }}
              placement="top"
            >
              <Button
                id={`editButton${moduleInfo.name}`}
                variant="body"
                onClick={() => setShowEditModuleModal(true)}
                className={`${styles.editModalButton} ${styles.moduleActionButton} btn-body`}
                style={{ display: 'flex' }}
              >
                <Icon iconName="pen-to-square" />
              </Button>
            </HelpPopover>
          </Authorization>

          {moduleInfo.disabled && (
            <div
              style={{
                display: 'inline-block',
                marginTop: '-15px'
              }}
            >
              <HelpPopover
                helpBoxProps={{
                  title: t('dashboard.projectRow.PlanIssue') || 'dashboard.projectRow.PlanIssue',
                  description:
                    t('dashboard.projectRow.cantSelect') || 'dashboard.projectRow.cantSelect',
                  note: [t('dashboard.projectRow.currentPlans')]
                }}
                placement="top"
              >
                <HelpIcon />
              </HelpPopover>
            </div>
          )}
        </div>
      </ListGroup.Item>
      {showEditModuleModal && (
        <EditModuleModal
          moduleInfo={moduleInfo}
          showModal={showEditModuleModal}
          updateModule={updateModule}
          onCloseRequest={(refresh) => {
            if (refresh) {
              props.onRefresh();
            }
            setShowEditModuleModal(false);
          }}
          currentModules={props.modules}
        />
      )}
    </>
  );
}

export default Module;
