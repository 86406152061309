import React, {
  DragEvent,
  forwardRef,
  memo,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import {
  cascadeType,
  Column,
  columnID,
  ComponentMap,
  fetchType,
  Relationship,
  TableUUID
} from '../../../types';
import { makeRelationship } from 'routes/studio/data/elements/factory';
import { useDispatch, useSelector } from 'react-redux';
import {
  setCreatingRelationship,
  setErrorMessage,
  setSelectedConnector,
  setSelectedFrame
} from '../../store/actions/studio';
import { DatabaseStudioState } from '../../store';
import TableEditor from '../../components/table_editor';
import styles from './styles.module.css';
import Icon from 'web_ui/icon';
import { v4 as uuidv4 } from 'uuid';
import { addRelationship } from '../../store/actions/root';
import SessionContext from 'modules/auth/store';
import { Preferences } from 'modules/auth/enum';
import { sleep } from 'utils/utils';
import { WorkboardContext } from 'web_ui/workboard';
import ColumnIcon from '../../components/column_icon';

/**  table data specification, because in its
 *   definition this attribute is generic
 */
export type TableData = {
  name: string;
  description: string;
  columns: string[];
  primaryKey: string[];
  foreignKey: string[];
  indexes: string[];
  relationships: string[];
};

export type TableFrameProps = {
  /** frame identifier */
  uuid: TableUUID;
  /** Table content data: ex: (name, description, etc) */
  content: { data: TableData };
  /** frame type ex:(TABLE, ENUM, WORKFLOW) */
  type: string;
  /** Number to position the frame on the `x` axis of the workboard */
  posX: number;
  /** Number to position the frame on the `y` axis of the workboard */
  posY: number;
  disabled: boolean;
};

export type ConnectionCoordinate = {
  originPoint: string;
  targetPoint: string;
};

/** possible cardinality between two entities */
export enum RelationshipTypes {
  ONE2MANY = 'ONE2MANY',
  MANY2ONE = 'MANY2ONE',
  MANY2MANY = 'MANY2MANY',
  ONE2ONE = 'ONE2ONE'
}

// Table editor dimensions when zoom = 100%.
const BASE_TABLE_EDITOR_DIMENSIONS = {
  width: 540,
  height: 460
};

/**
 * Type of Frame component used in DB Studio
 * to represent a database table .
 *
 * @component
 */
function TableFrame(props: TableFrameProps, ref: React.Ref<any>) {
  const session = useContext(SessionContext);
  // context tables array
  const tables = useSelector((state: DatabaseStudioState) => state.tables);
  // context enums array
  const enums = useSelector((state: DatabaseStudioState) => state.enums);
  // context columns array
  const columns = useSelector((state: DatabaseStudioState) => state.columns);
  const studio = useSelector((state: DatabaseStudioState) => state.studio);
  // context relationships array
  // const relationships = useSelector((state: DatabaseStudioState) => state.relationships);
  // context selected table
  const selectedTable = useSelector((state: DatabaseStudioState) => state.studio.selectedFrame);
  // boolean used to show or hide the Table Editor component
  const [showEditor, setShowEditor] = useState<boolean>(false);
  // reference of table wrapper component
  const tableWrapperRef = useRef<HTMLElement>(null);
  const tableRef = useRef<HTMLTableElement>(null);
  const workboard = useContext(WorkboardContext);
  const [overflow, setOverflow] = useState<{ overflowX: number; overflowY: number }>();

  const dispatch = useDispatch();

  // Show table editor when table is created
  useEffect(() => {
    // Set this event to close other editors
    const onEditorOpenEvent = new Event('openFrameEditor');

    async function showEditorOnTableCreate() {
      if (selectedTable === props.uuid) {
        // Do this so the editor open when creating a table with click
        await sleep(10);
        // Dispatch this event to close other editors
        document.dispatchEvent(onEditorOpenEvent);

        checkTableEditorOverflow();

        setShowEditor(true);
      }
    }

    showEditorOnTableCreate();
  }, [dispatch]);

  // Used to show and hide the table editor and manage the z-index order.
  useEffect(() => {
    // if the Frame Editor is opened...
    if (showEditor) {
      // if is open add listener, if not, remove it
      document.addEventListener('click', handleManageEditors);
      // This listener prevents opening more than one editor
      // when the editor opens after a table is created
      document.addEventListener('openFrameEditor', handleShowEditor);
    } else {
      document.removeEventListener('click', handleManageEditors);
      document.removeEventListener('openFrameEditor', handleShowEditor);
    }

    function handleShowEditor(event: Event) {
      setShowEditor(false);
      setOverflow(undefined);
    }

    /***  Event Listener that adds a screen-wide event so that
     *    the editor closes
     *    if it is outside the editing area
     */
    function handleManageEditors(event: Event) {
      const target = event.target || event.currentTarget;
      dispatch(setSelectedConnector(null));
      // if click was in a frame editor, do nothing, if not, close editor
      if (
        tableWrapperRef.current?.contains(target as Node) ||
        document.getElementById('advancedEditor')
      ) {
        event?.stopPropagation();
      } else {
        const frameElement = document.getElementById(selectedTable ? selectedTable : '');
        setShowEditor(false);
        setOverflow(undefined);
      }
    }

    // setShowEditor(true);
  }, [dispatch, props.uuid, showEditor, selectedTable]);

  // Used to handle the origin table on start create of a relationship
  function handleDragStart(
    ev: DragEvent<HTMLDivElement>,
    type: RelationshipTypes,
    pointNumber?: string
  ) {
    dispatch(setCreatingRelationship(true));
    // checks if there is at least 1 primary key to create the relationship
    if (type === 'ONE2MANY' && (!props.uuid || selectPkColumns(props.uuid).length < 1)) {
      ev.preventDefault();
      return dispatch(setErrorMessage('Error'));
    }
    // Use data transfer to send the source data of the new relationship to the destination table
    ev.dataTransfer.setData(
      'exocode/relationship-origin',
      JSON.stringify({ type: type, origin: props.uuid, originPoint: pointNumber })
    );
    ev.dataTransfer.dropEffect = 'copy';
  }

  // Event on drop, used to map the params (origin table, target table and the respectives columns)
  function handleRelationParams(ev: DragEvent<HTMLDivElement>, targetPoint: string) {
    // stop other mouse events
    ev.stopPropagation();
    ev.preventDefault();
    if (
      ev.dataTransfer.getData('exocode/relationship-origin') === null ||
      ev.dataTransfer.getData('exocode/relationship-origin') === ''
    )
      return;
    // used to map the origin table to create a relationship
    let inboundTable = '';
    // used to map the destiny table to create a relationship
    let outboundTable = '';
    const coordinatePoints: ConnectionCoordinate = {
      originPoint: '4',
      targetPoint: '7'
    };
    // Get the relationship origin data from the data transfer
    const relationshipConnect = JSON.parse(ev.dataTransfer.getData('exocode/relationship-origin'));

    if (!relationshipConnect) return console.log('Error on creating connection');
    // If this is a manyToOne, go back to do a OneToMany relationship.
    inboundTable = relationshipConnect.origin;
    outboundTable = props.uuid;

    const primaryKeys: Column[] = selectPkColumns(
      relationshipConnect.type === 'ONE2MANY' ? inboundTable : outboundTable
    );
    // trigger an error if there are no primary keys
    if (!primaryKeys || primaryKeys.length < 1)
      return console.log('Sorry the table must have a primary key');

    if (relationshipConnect.originPoint && targetPoint) {
      coordinatePoints.originPoint = relationshipConnect.originPoint;
      coordinatePoints.targetPoint = targetPoint;
    }

    const inboundTableName = tables[inboundTable].content.data.name;
    // Use the function to create and save on context the new relationship
    createRelationship(
      inboundTable,
      inboundTableName,
      outboundTable,
      relationshipConnect.type,
      primaryKeys.map((primaryKey: Column) => {
        return primaryKey.uuid;
      }),
      coordinatePoints
    );
  }

  // create the foreign key column, create the relationship and add on the context
  function createRelationship(
    fromTableID: TableUUID,
    fromTableName: string,
    toTableID: TableUUID,
    type: string,
    fromColumnIDs: columnID[],
    connectionPoints: ConnectionCoordinate
  ) {
    if (fromTableID === toTableID) return;
    // create a column as a foreign key on target table, and receive its identification key
    const components: ComponentMap = {};

    fromColumnIDs.map((fromColumn: columnID) => {
      if (type === 'ONE2MANY') {
        components[fromColumn] = uuidv4();
      } else {
        components[uuidv4()] = fromColumn;
      }
      return components;
    });

    if (toTableID !== '') {
      // Create a new relationship object to store on context
      const relationship: Relationship = Object.assign(
        {},
        makeRelationship(
          fromTableID,
          fromTableName,
          toTableID,
          components as ComponentMap,
          [cascadeType.PERSIST, cascadeType.MERGE, cascadeType.REMOVE] as cascadeType[],
          connectionPoints,
          type,
          true,
          fetchType['LAZY'] as fetchType
        )
      );
      dispatch(addRelationship(relationship));
      dispatch(setSelectedConnector(relationship.id));
      dispatch(setSelectedFrame(null));
    }
  }

  function getEditorScale() {
    if (workboard.scale >= 1) return 1 - Math.abs(workboard.scale - 1) / 2;
    else return 1 / workboard.scale;
  }

  function checkTableEditorOverflow() {
    if (!workboard.workboardRef || !workboard.workboardRef.current || !tableWrapperRef.current)
      return;

    const workboardRef = workboard.workboardRef.current;
    const scale = workboard.scale;

    const modifiedScale = getEditorScale();

    // Calculate table editor height.
    //! If the dimensions of the table editor changes then update BASE_TABLE_EDITOR_DIMENSIONS.
    const tableEditorHeight = BASE_TABLE_EDITOR_DIMENSIONS.height * scale * modifiedScale;

    // Check whether table editor overflows. Subtract 60 because navbar has a height of 60px.
    if (
      workboardRef.offsetHeight - (tableWrapperRef.current.getBoundingClientRect().top - 60) <
      tableEditorHeight
    ) {
      const overflowY =
        (workboardRef.offsetHeight -
          (tableWrapperRef.current.getBoundingClientRect().top - 60) -
          tableEditorHeight) /
        scale;
      setOverflow({ overflowX: 0, overflowY: overflowY });
    } else {
      setOverflow({ overflowX: 0, overflowY: 0 });
    }
  }

  // Open the table fast edit window
  function openTableEditor() {
    if (props.uuid) {
      const frameElement = document.getElementById(props.uuid ? props.uuid : '');
      if (frameElement) frameElement.style.zIndex = '4';

      checkTableEditorOverflow();

      setShowEditor(true);
    }
  }

  // Select all Primary keys of a specific table
  function selectPkColumns(tableID: TableUUID): Column[] {
    const pkColumns: Column[] = [];
    // iterate over the given list of table columns and return the primary keys
    tables[tableID].content.data.columns.map((columnID: columnID) => {
      columns[columnID] && columns[columnID].isPK && pkColumns.push(columns[columnID]);
      return null;
    });
    return pkColumns;
  }

  // Used to display the correct name when the type is an enum
  function handleReturnSelectedType(columnId: columnID) {
    const enumUUID = columns[columnId].enumUUID;
    if (enumUUID) {
      if (enums[enumUUID]) return enums[enumUUID].content.data.name;
      else return columns[columnId].type;
    } else {
      return columns[columnId].type;
    }
  }

  function handleOverChangePointStyle(id: string) {
    const pointRef = document.getElementById(id);
    if (pointRef) {
      pointRef.style.scale = '2.5';
      pointRef.classList.remove('bg-primary');
      pointRef.classList.add('bg-info');
    }
  }

  function handleLeaveChangePointStyle(id: string) {
    const pointRef = document.getElementById(id);
    if (pointRef) {
      pointRef.style.scale = '1';
      pointRef.classList.remove('bg-info');
      pointRef.classList.add('bg-primary');
    }
  }

  function renderColumnRow(column: Column) {
    if (column == null) {
      return null;
    }
    return (
      <tr id={column.uuid} key={column.uuid} className={`text-body ${styles.tableRow}`}>
        <td style={{ minWidth: '150px' }}> {column.name}</td>
        <td style={{ minWidth: '95px' }}>{handleReturnSelectedType(column.uuid)}</td>
        <td>
          <ColumnIcon column={column} />
        </td>
      </tr>
    );
  }

  function getListOfColumnsToDisplay() {
    const primaryKeyColumns = [];
    const normalColumns = [];
    for (const column of props.content.data.columns) {
      if (columns[column] && columns[column].isPK) {
        primaryKeyColumns.push(column);
      } else if (columns[column]) {
        normalColumns.push(column);
      }
    }

    let displayMaxColumns;
    // If HIDE_COLUMNS = false then show all columns.
    if (!session.preferences[Preferences.HIDE_COLUMNS]) {
      displayMaxColumns = props.content.data.columns.length;
      // If HIDE_COLUMNS = true then show what is configured by PREFERENCES.NUMBER_OF_COLUMNS.
    } else {
      displayMaxColumns =
        session.preferences[Preferences.NUMBER_OF_COLUMNS] > props.content.data.columns.length
          ? props.content.data.columns.length
          : session.preferences[Preferences.NUMBER_OF_COLUMNS];
    }

    const allColumns = primaryKeyColumns.concat(normalColumns);
    return allColumns.slice(0, displayMaxColumns);
  }

  const renderColumns = () => {
    const columnsToDisplay = getListOfColumnsToDisplay();

    return (
      <>
        {columnsToDisplay.map((columnId) => {
          return renderColumnRow(columns[columnId]);
        })}
        {props.content.data.columns.length === 0 && (
          <tr key={'columnId'} className={`${styles.newTableRow}`}>
            <td style={{ minWidth: '245px', userSelect: 'none' }}>
              {'Click to create new column'}
            </td>
          </tr>
        )}
        {columnsToDisplay.length < props.content.data.columns.length && (
          <tr>
            <td colSpan={3}>
              <p className={`bg-body-tertiary ${styles.iconMoreColumn}`}>
                <Icon iconName="ellipsis-h"></Icon>
              </p>
            </td>
          </tr>
        )}
      </>
    );
  };

  const isTableNameDefault = useCallback(
    (tableId: string): boolean => {
      const checkDefaultName = /^TABLE[0-9]*$/;
      return checkDefaultName.test(tables[tableId].content.data.name);
    },
    [tables]
  );

  return (
    <section
      id="listTables"
      ref={tableWrapperRef}
      className={
        selectedTable === props.uuid
          ? `${styles.tableFrameContainer} ${styles.selectedTable}`
          : `${styles.tableFrameContainer} ${props.disabled && styles.disabledTable}`
      }
      onDoubleClick={() => openTableEditor()}
      onClick={() => dispatch(setSelectedFrame(props.uuid))}
      onDrop={() => dispatch(setCreatingRelationship(false))}
      style={{ position: 'relative' }}
      translate="no"
    >
      {(selectedTable === props.uuid || studio.isCreatingRelationship) && (
        <>
          <div
            id={props.uuid + '0'}
            className={`bg-primary ${styles.tableFrameContainer} ${styles.oneToManyPoint}`}
            style={{ left: '-14px', top: '-14px' }}
            title={'Create new OneToMany relationship'}
            draggable={true}
            onDragStart={(e) => {
              handleDragStart(e, RelationshipTypes['ONE2MANY'], '0');
            }}
            onDrop={(ev) => {
              handleRelationParams(ev, '0');
              handleLeaveChangePointStyle(props.uuid + '0');
              dispatch(setCreatingRelationship(false));
            }}
            onDragOver={() => handleOverChangePointStyle(props.uuid + '0')}
            onDragLeave={() => handleLeaveChangePointStyle(props.uuid + '0')}
          />
          <div
            id={props.uuid + '7'}
            className={`bg-primary ${styles.tableFrameContainer} ${styles.oneToManyPoint}`}
            style={{
              left: '-14px',
              bottom: tableRef.current?.clientHeight ? tableRef.current?.offsetHeight / 2 + -10 : 0
            }}
            title={'Create new OneToMany relationship'}
            draggable={true}
            onDragStart={(e) => {
              handleDragStart(e, RelationshipTypes['ONE2MANY'], '7');
            }}
            onDrop={(ev) => {
              handleRelationParams(ev, '7');
              handleLeaveChangePointStyle(props.uuid + '7');
              dispatch(setCreatingRelationship(false));
            }}
            onDragOver={() => handleOverChangePointStyle(props.uuid + '7')}
            onDragLeave={() => handleLeaveChangePointStyle(props.uuid + '7')}
          />
          <div
            id={props.uuid + '6'}
            className={`bg-primary ${styles.tableFrameContainer} ${styles.oneToManyPoint}`}
            style={{ left: '-14px', bottom: '-14px' }}
            title={'Create new OneToMany relationship'}
            draggable={true}
            onDragStart={(e) => {
              handleDragStart(e, RelationshipTypes['ONE2MANY'], '6');
            }}
            onDrop={(ev) => {
              handleRelationParams(ev, '6');
              handleLeaveChangePointStyle(props.uuid + '6');
              dispatch(setCreatingRelationship(false));
            }}
            onDragOver={() => handleOverChangePointStyle(props.uuid + '6')}
            onDragLeave={() => handleLeaveChangePointStyle(props.uuid + '6')}
          />
        </>
      )}
      <div ref={tableRef}>
        <table
          id={props.content.data.name}
          className={`border border-3 border-body ${styles.tableFrame}`}
          title={props.content.data.description}
          onDrop={(ev) => {
            handleRelationParams(ev, '0');
            handleLeaveChangePointStyle(props.uuid + '0');
            dispatch(setCreatingRelationship(false));
          }}
          ref={ref}
        >
          <thead>
            <tr className={`text-body-emphasis bg-info-subtle border-bottom ${styles.tableTitle}`}>
              <th colSpan={50}>{props.content.data.name ? props.content.data.name : 'No name'}</th>
            </tr>
          </thead>

          <tbody className={`bg-body`}>{renderColumns()}</tbody>
          <tfoot></tfoot>
        </table>
      </div>

      {(selectedTable === props.uuid || studio.isCreatingRelationship) && (
        <>
          <div
            id={props.uuid + '2'}
            className={`bg-primary ${styles.tableFrameContainer} ${styles.oneToManyPoint}`}
            style={{ right: '-14px', top: '-14px' }}
            title={'Create new OneToMany relationship'}
            draggable={true}
            onDragStart={(e) => {
              handleDragStart(e, RelationshipTypes['ONE2MANY'], '2');
            }}
            onDrop={(ev) => {
              handleRelationParams(ev, '2');
              handleLeaveChangePointStyle(props.uuid + '2');
              dispatch(setCreatingRelationship(false));
            }}
            onDragOver={() => handleOverChangePointStyle(props.uuid + '2')}
            onDragLeave={() => handleLeaveChangePointStyle(props.uuid + '2')}
          />
          <div
            id={props.uuid + '3'}
            className={`bg-primary ${styles.tableFrameContainer} ${styles.oneToManyPoint}`}
            style={{
              right: '-14px',
              bottom: tableRef.current?.clientHeight ? tableRef.current?.offsetHeight / 2 + -10 : 0
            }}
            title={'Create new OneToMany relationship'}
            draggable={true}
            onDragStart={(e) => {
              handleDragStart(e, RelationshipTypes['ONE2MANY'], '3');
            }}
            onDrop={(ev) => {
              handleRelationParams(ev, '3');
              handleLeaveChangePointStyle(props.uuid + '3');
              dispatch(setCreatingRelationship(false));
            }}
            onDragOver={() => handleOverChangePointStyle(props.uuid + '3')}
            onDragLeave={() => handleLeaveChangePointStyle(props.uuid + '3')}
          />
          <div
            id={props.uuid + '4'}
            className={`bg-primary ${styles.tableFrameContainer} ${styles.oneToManyPoint}`}
            style={{ right: '-14px', bottom: '-14px' }}
            title={'Create new OneToMany relationship'}
            draggable={true}
            onDragStart={(e) => {
              handleDragStart(e, RelationshipTypes['ONE2MANY'], '4');
            }}
            onDrop={(ev) => {
              handleRelationParams(ev, '4');
              handleLeaveChangePointStyle(props.uuid + '4');
              dispatch(setCreatingRelationship(false));
            }}
            onDragOver={() => handleOverChangePointStyle(props.uuid + '4')}
            onDragLeave={() => handleLeaveChangePointStyle(props.uuid + '4')}
          />
          <div
            id={props.uuid + '5'}
            className={`bg-primary ${styles.tableFrameContainer} ${styles.oneToManyPoint}`}
            style={{ right: '165px', bottom: '-14px' }}
            title={'Create new OneToMany relationship'}
            draggable={true}
            onDragStart={(e) => {
              handleDragStart(e, RelationshipTypes['ONE2MANY'], '5');
            }}
            onDrop={(ev) => {
              handleRelationParams(ev, '5');
              handleLeaveChangePointStyle(props.uuid + '5');
              dispatch(setCreatingRelationship(false));
            }}
            onDragOver={() => handleOverChangePointStyle(props.uuid + '5')}
            onDragLeave={() => handleLeaveChangePointStyle(props.uuid + '5')}
          ></div>
          <div
            id={props.uuid + '1'}
            className={`bg-primary ${styles.tableFrameContainer} ${styles.oneToManyPoint}`}
            style={{ right: '165px', top: '-14px' }}
            title={'Create new OneToMany relationship'}
            draggable={true}
            onDragStart={(e) => {
              handleDragStart(e, RelationshipTypes['ONE2MANY'], '1');
            }}
            onDrop={(ev) => {
              handleRelationParams(ev, '1');
              handleLeaveChangePointStyle(props.uuid + '1');
              dispatch(setCreatingRelationship(false));
            }}
            onDragOver={() => handleOverChangePointStyle(props.uuid + '1')}
            onDragLeave={() => handleLeaveChangePointStyle(props.uuid + '1')}
          ></div>
        </>
      )}

      {showEditor && overflow != null && (
        <TableEditor
          tableID={props.uuid}
          columnList={props.content.data.columns}
          overflow={overflow}
          editorScale={getEditorScale()}
          focusOnTableName={isTableNameDefault(props.uuid)}
        />
      )}
    </section>
  );
}

export default memo(forwardRef(TableFrame));
