import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Combobox, Transition } from '@headlessui/react';
import { BsChevronExpand } from 'react-icons/bs';
import { IoMdCheckmark } from 'react-icons/io';
import styles from './styles.module.css';
import useSession from 'hooks/useSession';
import { sleep } from 'utils/utils';
import { useSelector } from 'react-redux';
import { FunctionEditorState } from 'web_ui/function_editor/store/types/function_editor_state';
import { useTranslation } from 'react-i18next';

type ComboboxInputProps = {
  items: unknown[];
  selectedItem: unknown;
  handleChangeSelectedItem: (item: unknown) => void;
  getDisplayNameFromItem: (item: unknown) => string;
  // ? Why not receive a ReactNode as props that renders the combobox option instead?
  getDisplaySubNameFromItem?: (item: unknown) => string;
  getDisplayDescriptionFromItem?: (item: unknown) => string;
  label?: string;
  placeholder?: string;
  // ? Why not receive a className as props?
  isFromSideBar?: boolean;
  isFromArgument?: boolean;
  // ? Remove options...
  options?: any;
};

// ! This is supposed to be a generic component.
// ! Don't pollute this component with specific logic.
export function ComboboxInput({
  items,
  selectedItem,
  handleChangeSelectedItem,
  getDisplayNameFromItem,
  getDisplaySubNameFromItem,
  getDisplayDescriptionFromItem,
  label,
  placeholder,
  isFromSideBar,
  isFromArgument,
  options
}: ComboboxInputProps) {
  const comboBoxOptionsRef = useRef<HTMLUListElement>(null);
  const functionId = useSelector((state: FunctionEditorState) => state.editor.callerId);
  const functions = useSelector((state: FunctionEditorState) => state.functions);
  const actions = useSelector((state: FunctionEditorState) => state.actions);
  const [query, setQuery] = useState('');
  const isDarkTheme = useSession()[0].preferences['exocode-theme'];
  const { t } = useTranslation();
  const [currentPositionY, setCurrentPositionY] = useState<number>(0);

  const filteredItems: unknown[] =
    query === ''
      ? items
      : items.filter((item) =>
          getDisplayNameFromItem(item)
            .toLowerCase()
            .replace(/\s+/g, '')
            .includes(query.toLowerCase().replace(/\s+/g, ''))
        );

  const handleScrollIntoView = async () => {
    await sleep(50);
    comboBoxOptionsRef.current?.scrollIntoView();
  };

  // ! Should be removed.
  const correctStyle = (): React.CSSProperties => {
    if (isFromArgument) {
      return { width: 165 };
    } else if (isFromSideBar && !isFromArgument) {
      return {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start'
      };
    } else {
      return {};
    }
  };

  // ! This shouldn't be here.
  useEffect(() => {
    if (options && options.filterByDataType?.name === 'filterByFunctionReturnDataType') {
      if (
        !functions[functionId]?.returnType.type ||
        functions[functionId]?.returnType.type === 'VOID'
      ) {
        handleChangeSelectedItem({ name: '', uuid: '', id: '', subName: '', description: '' });
      }
    }
  }, [functionId, functions]);

  const correctStyleCombo = (): React.CSSProperties => {
    return filteredItems.length == 3
      ? {
          position: 'absolute',
          zIndex: 9999,
          width: 'inherit',
          maxHeight: 200,
          marginTop: -160
        }
      : filteredItems.length == 4
      ? {
          position: 'absolute',
          zIndex: 9999,
          width: 'inherit',
          maxHeight: 200,
          marginTop: -200
        }
      : (currentPositionY != 0 && currentPositionY > 450 && filteredItems.length >= 5) ||
        (Object.keys(actions).length <= 2 && filteredItems.length > 1)
      ? {
          position: 'absolute',
          zIndex: 9999,
          width: 'inherit',
          maxHeight: 200,
          marginTop: -240
        }
      : {
          position: 'absolute',
          zIndex: 9999,
          width: 'inherit',
          maxHeight: 200
        };
  };

  return (
    <>
      <div
        className={`${
          isDarkTheme ? styles.ComboboxDarkThemeVariables : styles.ComboboxLightThemeVariables
        } ${styles.ComboboxWrapper}`}
        style={correctStyle()}
      >
        {label && <div className={styles.ComboboxLabel}>{label}</div>}
        <Combobox value={selectedItem} onChange={handleChangeSelectedItem}>
          <div className={styles.Combobox}>
            <div
              className={`${isFromSideBar || isFromArgument ? '' : styles.ComboboxInputAndButton}`}
            >
              <Combobox.Input
                className={styles.ComboboxInput}
                displayValue={() => getDisplayNameFromItem(selectedItem)}
                onChange={(event) => setQuery(event.target.value)}
                autoComplete="off"
                spellCheck={false}
                placeholder={placeholder}
                style={isFromSideBar ? { width: 165 } : {}}
              />
              <Combobox.Button
                className={styles.ComboboxButton}
                onClick={handleScrollIntoView}
                onMouseDown={(ev: any) => {
                  setCurrentPositionY(ev.clientY);
                }}
              >
                <BsChevronExpand className={styles.ComboboxButtonIcon} aria-hidden="true" />
              </Combobox.Button>
            </div>
            <Transition
              as={Fragment}
              leave={styles.TransitionLeave}
              leaveFrom={styles.TransitionLeaveFrom}
              leaveTo={styles.TransitionLeaveTo}
              afterLeave={() => setQuery('')}
            >
              <Combobox.Options
                ref={comboBoxOptionsRef}
                className={styles.ComboboxOptions}
                style={correctStyleCombo()}
              >
                {filteredItems.length === 0 && query === '' ? (
                  <>
                    {/* ! Just add a children prop instead of adding new specific stuff here. */}
                    {!functions[functionId]?.returnType.type ||
                    functions[functionId]?.returnType.type === 'VOID' ? (
                      <Combobox.Options>
                        <div className={styles.ComboboxNothingFound}>
                          {t('returnToVoidFunction')}
                        </div>
                      </Combobox.Options>
                    ) : (
                      <Combobox.Options>
                        <div className={styles.ComboboxNothingFound}>Nothing found.</div>
                      </Combobox.Options>
                    )}
                  </>
                ) : (
                  filteredItems.map((item, index) => (
                    <Combobox.Option
                      key={index}
                      className={({ active }) =>
                        `${styles.ComboboxOption} d-flex justify-content-between ${
                          active ? styles.ComboboxOptionActive : styles.ComboboxOptionInactive
                        }`
                      }
                      value={item}
                    >
                      {({ selected, active }) => (
                        <>
                          <div className="d-flex flex-column w-100">
                            <div className="d-flex justify-content-between">
                              <span
                                className={`${styles.ComboboxOptionName} ${
                                  selected
                                    ? styles.ComboboxOptionNameSelected
                                    : styles.ComboboxOptionNameNotSelected
                                }`}
                              >
                                {getDisplayNameFromItem(item)}
                              </span>
                              {getDisplaySubNameFromItem &&
                                getDisplaySubNameFromItem(item) !== '' && (
                                  <span
                                    className={`ms-1 d-md-none d-sm-none d-lg-none d-xl-inline ${styles.ComboboxOptionDescriptionActive}`}
                                  >
                                    ({getDisplaySubNameFromItem(item)})
                                  </span>
                                )}
                            </div>
                            {getDisplayDescriptionFromItem &&
                              getDisplayDescriptionFromItem(item) !== '' && (
                                <span
                                  className={`text-start ${styles.ComboboxOptionDescriptionActive}`}
                                >
                                  /{getDisplayDescriptionFromItem(item)}
                                </span>
                              )}
                          </div>

                          {selected && (
                            <span
                              className={`${styles.ComboboxSelectedOptionIcon} ${
                                active
                                  ? styles.ComboboxSelectedOptionIconActive
                                  : styles.ComboboxSelectedOptionIconNotActive
                              }`}
                            >
                              <IoMdCheckmark
                                className={styles.ComboboxCheckmarkIcon}
                                aria-hidden="true"
                              />
                            </span>
                          )}
                        </>
                      )}
                    </Combobox.Option>
                  ))
                )}
              </Combobox.Options>
            </Transition>
          </div>
        </Combobox>
      </div>
    </>
  );
}
